// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})
const {userToken} = require('../utils')

//Loading all grades
const loadGrades = (dispatch) => {
	return $http.get('grade', {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADES',
      		payload : res.data
    	})
	})
}

//Loading a specific grade
function loadGrade(dispatch, {id}){
	return $http.get(`grade/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADE',
      		payload : res.data
    	})
	})
}

//Loading a specific grade
function emptyGrade(dispatch){
	dispatch({
  		type : 'SET_GRADE',
  		payload : null
	})
}

//Creating a new grade
function createGrade(dispatch, {data}){
	return $http.post(`grade`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_GRADE',
      		payload : res.data
    	})
	})
}

//Updating a grade
function updateGrade(dispatch, {id, data}){
	return $http.put(`grade/${id}`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADE',
      		payload : res.data
    	})
	})
}

//Deleting a grade
function deleteGrade(dispatch, {id}){
	return $http.delete(`grade/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_GRADE',
      		payload : {_id : id}
    	})
	})
}

const gradeServices = {
	loadGrades, loadGrade, createGrade, updateGrade, emptyGrade, deleteGrade
}

export default gradeServices