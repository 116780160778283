// Regarding API
const axios = require("axios");
const { baseURL } = require("../assets/js/config");
const $http = axios.create({
  baseURL,
});
const { userToken } = require("../utils");

//Loading all coupons
const loadCoupons = (dispatch) => {
  return $http
    .get("coupon", {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "SET_COUPONS",
        payload: res.data,
      });
    });
};

//Loading a specific COUPON
function loadCoupon(dispatch, { id }) {
  return $http
    .get(`coupon/${id}`, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "SET_COUPON",
        payload: res.data,
      });
    });
}

//Loading a specific coupon
function emptyCoupon(dispatch) {
  dispatch({
    type: "SET_COUPON",
    payload: null,
  });
}

//Creating a new coupon
function createCoupon(dispatch, { data }) {
  return $http
    .post(`coupon`, data, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "ADD_COUPON",
        payload: res.data,
      });
    });
}

//Updating a coupon
function updateCoupon(dispatch, { id, data }) {
  return $http
    .put(`coupon/${id}`, data, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "UPDATE_COUPON",
        payload: { data: res.data, id },
      });
    });
}

//Deleting a COUPON
function deleteCoupon(dispatch, { id }) {
  return $http
    .delete(`coupon/${id}`, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "REMOVE_COUPON",
        payload: { _id: id },
      });
    });
}

const couponServices = {
  loadCoupons,
  createCoupon,
  deleteCoupon,
  loadCoupon,
  emptyCoupon,
  updateCoupon,
};

export default couponServices;
