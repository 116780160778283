import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Form, Button, Spinner} from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import ViewFile from '../../components/General/ViewFile'
// Import React FilePond
import { FilePond } from 'react-filepond'

// Importing services
import gradeServices from '../../services/grade.service'
import subjectServices from '../../services/subject.service'
import gradeSubjectServices from '../../services/grade_subject.service'
import {baseURL} from '../../assets/js/config'

const SetGradeSubject = ({grade_subject_id, onHide}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [isLoading, setLoading] = useState(false)
  const [grade, setGrade] = useState('')
  const [subject, setSubject] = useState('')
  const [remarks, setRemarks] = useState('')
  const [editfiles, setEditFiles] = useState([])
  // Subject State
  const subjectState = useSelector(state => state.subject)
  const subjects = subjectState.subjects
  // Grade State
  const gradeState = useSelector(state => state.grade)
  const grades = gradeState.grades
  // Grade Subject State
  // const gradeSubjectState = useSelector(state => state.grade_subject)
  // const grade_subject = gradeSubjectState.current_grade_subject

  //Files
  const [files, setFiles] = useState([])
  //On created
  useEffect(() => {
    //Loading Subjects
    subjectServices.loadSubjects(dispatch)
    //Loading Grades
    gradeServices.loadGrades(dispatch)

  }, [dispatch])

  const proceed = () => {
    setLoading(_ => true)
    const gs_data = {
      grade,
      subject,
      remarks,
      editfiles,
      files
    }

    if(grade_subject_id){
      // For updating
      gradeSubjectServices.updateGradeSubject(dispatch, {
        id : grade_subject_id,
        data : gs_data
      }).then(() => {
        addToast('Grade Subject updated successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
        setLoading(_ => false)
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
        setLoading(_ => false)
      })
    }else{
      // For creating
      gradeSubjectServices.createGradeSubject(dispatch, {
        data : gs_data
      }).then(() => {
        addToast('Grade Subject created successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
        setLoading(_ => false)
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
        setLoading(_ => false)
      })
    } 
  }

  // Watching for new grade_subject or created
  useEffect(() => {
    if(grade_subject_id){
      setLoading(true)
      gradeSubjectServices.loadGradeSubject(dispatch, {id : grade_subject_id}).then((res) => {
        let res_data = res.data
        setGrade(res_data.grade._id)
        setSubject(res_data.subject._id)
        setRemarks(res_data.remarks)

        let local_editfiles = res_data.files.map(file_url => {
          return {
            file : file_url,
            is_deleted : false
          }
        })
        setEditFiles(local_editfiles)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        addToast(err.response.data, { appearance: 'error' })
      }) 
    }

  }, [grade_subject_id, dispatch, addToast])

  const removeFile = (file_i) => {
    let local_editfiles = JSON.parse(JSON.stringify(editfiles))
    local_editfiles[file_i].is_deleted = true
    setEditFiles(local_editfiles)
  }

  const filepondServer = {
    url : `${baseURL}`,
    process: {
      url: `upload`,
      method: 'POST',
      timeout: 7000,
      onload : (file_arr) => {
        // This return object is a string.
        file_arr = JSON.parse(file_arr)
        setFiles(old_files => [...old_files, ...file_arr])
      } 
    } 
  }

  return (
    <div>
      { isLoading ? <Spinner className="btn-center" animation="border" /> : (
      <div>
        <Form.Group>
          <Form.Label>Grade</Form.Label>
          <Form.Control as="select" value={grade} onChange={(e) => setGrade(e.target.value)}>
            <option value={null}  hidden>Select</option>
            {
              grades.map(lgrade => {
                return <option key={lgrade._id} value={lgrade._id}>{lgrade.name}</option>
              })
            }
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Subject</Form.Label>
          <Form.Control as="select" value={subject} onChange={(e) => setSubject(e.target.value)}>
            <option value={null}  hidden>Select</option>
            {
              subjects.map(lsubject => {
                return <option key={lsubject._id} value={lsubject._id} >{lsubject.name}</option>
              })
            }
          </Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Remarks</Form.Label>
          <Form.Control value={remarks} as="textarea" onChange={(e) => setRemarks(e.target.value)}></Form.Control>
        </Form.Group>

        { (editfiles && editfiles.filter(file_obj => !file_obj.is_deleted).length) ? (
        <div>
          <h3 className="text-center">Uploaded Files</h3>
          <div className="row">
            { editfiles.filter(file_obj => !file_obj.is_deleted).map((file, file_i) => {
              return (
              <div key={`FILE_${file_i}`} className="col-6">
                <ViewFile show_remove={true} url={file.file} onRemove={() => removeFile(file_i)} />
              </div>
            ) }) }
          </div>
        </div>) : ''}

        <Form.Group>
          <Form.Label>Course Material</Form.Label>
          <FilePond allowMultiple={true} maxFiles={5} name="files" server={filepondServer}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>' />  
        </Form.Group>      

        <Button className="btn-center" onClick={() => proceed()}>{grade_subject_id ? 'Update' : 'Create'}</Button>
      </div>
      ) }
    </div>
  )
}

export default SetGradeSubject