// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})
const {userToken} = require('../utils')

//Loading all subjects
const loadSubjects = (dispatch) => {
	return $http.get('subject', {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SUBJECTS',
      		payload : res.data
    	})
	}).catch(err => {
		dispatch({
      		type : 'SET_SUBJECTS',
      		payload : []
    	})
	})
}

//Loading a specific subject
function loadSubject(dispatch, {id}){
	return $http.get(`subject/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SUBJECT',
      		payload : res.data
    	})
	}).catch(err => {
		dispatch({
      		type : 'SET_SUBJECT',
      		payload : null
    	})
	})
}

//Creating a new subject
function createSubject(dispatch, {data}){
	return $http.post(`subject`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_SUBJECT',
      		payload : res.data
    	})
	}).catch(err => {
		dispatch({
      		type : 'ADD_SUBJECT',
      		payload : null
    	})
	})
}

//Updating a subject
function updateSubject(dispatch, {id, data}){
	return $http.put(`subject/${id}`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SUBJECT',
      		payload : res.data
    	})
	})
}

//Empty current subject
function emptySubject(dispatch){
	dispatch({
  		type : 'SET_SUBJECT',
  		payload : null
	})
}

//Deleting a subject
function deleteSubject(dispatch, {id}){
	return $http.delete(`subject/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_SUBJECT',
      		payload : {_id : id}
    	})
	})
}

const subjectServices = {
	loadSubjects, loadSubject, createSubject, updateSubject, emptySubject, deleteSubject
}

export default subjectServices