import './index.css'
const {TIMEZONES} = require('../Common/utils')

const TimezoneColorLabel = () => {	
	return (
		<div className="row">
			{ Object.keys(TIMEZONES).map((timezone_key, timezone_index) => {
				const timezone = TIMEZONES[timezone_key]
				return (
					<div key={`TIMEZONE_${timezone_index}`} className="col">
						<span className="timezone-color-label" style={{background : timezone.style.color}}></span>
						<p className="mb-0">{timezone_key}</p>
					</div>
				)
			})
			}
		</div>
	)
}

export default TimezoneColorLabel