const initialState = {
	reminders : [],
}

const remindersReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_REMINDERS':
		new_state = {
			...state,
			reminders : action.payload
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default remindersReducer