// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})
const {userToken} = require('../utils')

//Loading all materials
const loadMaterials = (dispatch) => {
	return $http.get('material', {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_MATERIALS',
      		payload : res.data
    	})
	})
}

//Loading a specific material
function loadMaterial(dispatch, {id}){
	return $http.get(`material/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_MATERIAL',
      		payload : res.data
    	})
	})
}

//Loading a specific material
function emptyMaterial(dispatch){
	return new Promise((resolve, reject) => {
		try{
			resolve(dispatch({
				type : 'SET_MATERIAL',
				payload : null
			}))
		}catch(err){
			reject(err)
		}
	})
	
}

//Creating a new material
function createMaterial(dispatch, {data}){
	return $http.post(`material`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_MATERIAL',
      		payload : res.data
    	})
	})
}

//Updating a material
function updateMaterial(dispatch, {id, data}){
	return $http.put(`material/${id}`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_MATERIAL',
      		payload : res.data
    	})
	})
}

//Deleting a material
function deleteMaterial(dispatch, {id}){
	return $http.delete(`material/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_MATERIAL',
      		payload : {_id : id}
    	})
	})
}

const materialServices = {
	loadMaterials, loadMaterial, createMaterial, updateMaterial, emptyMaterial, deleteMaterial
}

export default materialServices