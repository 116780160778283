import {useState, useEffect} from 'react'

const SetTeacherTimeTable = ({timetable, view, onchange}) => {
	//Creating an array for hours
	let hour_start = 0, hour_end = 23.5
	let current_hour = hour_start
	let hours = []
	while(current_hour<=hour_end){
		let hour_part = parseInt(Math.floor(current_hour)).toString().padStart(2, '0')
		let mins_part = (current_hour > hour_part) ? '30' : '00'
		let ctime = `${hour_part}:${mins_part}`
		hours.push(ctime)
		current_hour += 0.5
	}
	// let hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
	let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	// Defining current states for time_table
	let initial_time_table = timetable ? timetable : {}
	const [time_table, setTimeTable] = useState(initial_time_table)
	
	// function updateTimeTable({hour, day}){
	// 	// Updating the timetable object if not view type
	// 	if(!view){
	// 		let new_time_table = JSON.parse(JSON.stringify(time_table))
	// 		if(!new_time_table[day]){
	// 			// If day key not present create it
	// 			new_time_table[day] = {}
	// 		}

	// 		if(!new_time_table[day][hour]){
	// 			new_time_table[day][hour] = true
	// 		}else{
	// 			//Remove this key
	// 			delete new_time_table[day][hour]
	// 		}
	// 		setTimeTable(new_time_table)
	// 		onchange(new_time_table)
	// 	}
	// }

	function updateTimeTable(cell_key){
		// Updating the timetable object if not view type
		if(!view){
			let new_time_table = {...time_table}
			if(!new_time_table[cell_key]){
				// If cell key not present create it
				new_time_table[cell_key] = true
			}else{
				delete new_time_table[cell_key]
			}

			setTimeTable(new_time_table)
			onchange(new_time_table)
		}
	}

	// Check if day,hour is present in user's current timetable
	function isAvailable(cell_key){
		return (time_table[cell_key])
	}

	useEffect(() => {
		if(timetable){
			setTimeTable(timetable)
		}
	}, [timetable])

	return (
		<div className="table-responsive">
			<table className="table table-lg table-bordered no-padding all-text-center">
				<tbody>
					<tr>
						<th></th>
						{
							days.map((day, day_i) => {
								return <td key={`HEAD_COL_${day_i}`}>{day}</td>
							})
						}
					</tr>
					{
						hours.map((hour, hour_i) => {
							return <tr key={`DAY_${hour_i}`}><td>{hour}</td>
								{	
									days.map((day, day_i) => {
										return <td className={ isAvailable(`${hour_i}-${day_i}`) ? 'green-bg' : 'transparent-bg' } key={`HEAD_COL_${hour_i}_${day_i}`} onClick={() => updateTimeTable(`${hour_i}-${day_i}`)}></td>
									})
								}
							</tr>
						})
					}
				</tbody>
			</table>
		</div>
	)
}

export default SetTeacherTimeTable