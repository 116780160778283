// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})
const {userToken} = require('../utils')

//Loading all teachers
function loadTeachers(dispatch, filter={}){
	let query_arr = ['type=T']
	for(let filter_key in filter){
		let val = filter[filter_key]
		
		// Dealing with time parameter
		if(filter_key === 'time'){
			if(val && val.length && (val[0] !== val[1])){
				val = val.join(",")
			}else{
				val = null
			}
		}

		if((val !== undefined) && (val !== null) && (val !== '')){
			query_arr.push(`${filter_key}=${encodeURIComponent(val)}`)
		}
	}
	const query_str = query_arr.join("&")
	return $http.get(`user?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_TEACHERS',
      		payload : res.data
    	})
	})
}

//Creating a new user
function createUser(dispatch, {data}){
	return $http.post(`user`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_TEACHERS',
      		payload : []
    	})
	})
}

//Updating a new user
function updateUser(dispatch, {id, data}){
	return $http.put(`user/${id}`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_USER',
      		payload : res.data
    	})
	})
}

//Loading specific user details
function loadUser(dispatch, {id, data}){
	return $http.get(`user/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_USER',
      		payload : res.data
    	})

    	return res
	})
}

//Loading specific user details
function emptyUser(dispatch){
	
	dispatch({
  		type : 'SET_USER',
  		payload : null
	})
}

//Loading specific user details
function deleteUser(dispatch, {id}){
	return $http.delete(`user/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_USER',
      		payload : { _id : id }
    	})
	})
}

//Loading all students
function loadStudents(dispatch){
	return $http.get(`user?type=S`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_STUDENTS',
      		payload : res.data
    	})
	})
}

const userServices = {
	loadTeachers, createUser, loadUser, deleteUser, loadStudents, updateUser, emptyUser
}

export default userServices