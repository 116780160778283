import { useState, useEffect } from "react";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import subscriberServices from "../../services/subscriber.service";

const SetSubscriber = ({ current_subscriber, onHide }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [isLoading, setLoading] = useState(false);
  const [subscriberChildren, setSubscriberChildren] = useState(
    current_subscriber.children
  );
  const [subscriberSessions, setSubscriberSessions] = useState(
    current_subscriber.sessions
  );
  const [subscriberGrade, setSubscriberGrade] = useState(
    current_subscriber.grade
  );
  const [subscriberSubjects, setSubscriberSubjects] = useState(
    current_subscriber.subjects
  );

  const EditSubscriberData = () => {
    let subscriber_data = {
      chidlren: subscriberChildren,
      sessions: subscriberSessions,
      grade: subscriberGrade,
      subjects: subscriberSubjects,
      user_id: current_subscriber._id,
      action: "update",
    };
    subscriberServices
      .updateSubscriber(dispatch, { subscriber_data })
      .then((res) => {
        // if (res.data.success) {
        //   addToast(res.data.msg, { appearance: "success" });
        //   subscriberServices.loadSubscriber(dispatch, {
        //     id: res.data.data.stripe_subscription_id,
        //   });
        // }
      })
      .catch((err) => {
        addToast(err.message, { appearance: "error" });
      });
  };

  const onChangeSubjects = (e) => {
    setSubscriberSubjects(
      Array.from(e.currentTarget.selectedOptions, (v) => v.value)
    );
  };

  return (
    <div>
      {isLoading || current_subscriber === null ? (
        <Spinner className="btn-center" animation="border" />
      ) : (
        <div>
          {console.log("subscriber", current_subscriber)}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Subscriber Children</Form.Label>
                <Form.Control
                  type="text"
                  value={subscriberChildren.join(", ")}
                  onChange={(e) =>
                    setSubscriberChildren(e.target.value.split(","))
                  }
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Subscriber Sessions</Form.Label>
                <Form.Control
                  type="number"
                  value={subscriberSessions}
                  onChange={(e) => setSubscriberSessions(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Subscriber Grade</Form.Label>
                <Form.Control
                  as="select"
                  value={subscriberGrade}
                  onChange={(e) => setSubscriberGrade(e.target.value)}
                >
                  <option value="">Select Subject</option>
                  <option value="k">k</option>
                  {[...Array(10)].map((e, index) => (
                    <option value={index + 1} key={index}>
                      {index + 1}
                    </option>
                  ))}
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Subscriber Subjects</Form.Label>
                <Form.Control
                  as="select"
                  value={subscriberSubjects}
                  onChange={(e) => onChangeSubjects(e)}
                  multiple
                >
                  {[...Array(10)].map((e, index) => (
                    <option value={index + 1} key={index}>
                      {index + 1}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Button className="btn-center" onClick={EditSubscriberData}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default SetSubscriber;
