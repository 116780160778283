export const getAllHours = () => {
	//Creating an array for hours
	let hour_start = 0, hour_end = 23.5
	let current_hour = hour_start
	let hours = []
	while(current_hour<=hour_end){
		let hour_part = parseInt(Math.floor(current_hour)).toString().padStart(2, '0')
		let mins_part = (current_hour > hour_part) ? '30' : '00'
		let ctime = `${hour_part}:${mins_part}`
		hours.push(ctime)
		current_hour += 0.5
	}
	return hours
}

