const initialState = {
  current_coupon: null,
  coupons: [],
};

const couponsReducer = function (state = initialState, action) {
  let new_state;
  switch (action.type) {
    case "SET_COUPONS":
      new_state = {
        ...state,
        coupons: action.payload,
      };
      break;
    case "UPDATE_COUPON":
      let couponIndex = state.coupons.findIndex(
        (item) => item._id === action.payload.id
      );
      let coupons = [...state.coupons];
      coupons[couponIndex] = { ...action.payload.data };
      new_state = {
        ...state,
        coupons,
        current_coupon: action.payload.data,
      };
      break;
    case "SET_COUPON":
      new_state = {
        ...state,
        current_coupon: action.payload,
      };
      break;
    case "ADD_COUPON":
      new_state = {
        ...state,
        coupons: state.coupons.concat(action.payload),
      };
      break;

    case "REMOVE_COUPON":
      new_state = {
        ...state,
        coupons: [
          ...state.coupons.filter(
            (coupon) => coupon._id !== action.payload._id
          ),
        ],
      };
      break;

    default:
      new_state = state;
  }
  return new_state;
};

export default couponsReducer;
