// This is the list of all teachers
import {Modal} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useToasts } from 'react-toast-notifications';
import {Link} from 'react-router-dom'
import PageHeading from '../../components/PageHeading'
import SetGradeSubject from '../../components/GradeSubject/SetGradeSubject'
import Confirm from '../../components/General/Confirm'
import gradeSubjectServices from '../../services/grade_subject.service'
import { userType } from '../../utils'

const GradeSubject = () => {
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const gradeSubjectState = useSelector(state => state.grade_subject)
  const grade_subjects = gradeSubjectState.grade_subjects
  // Grade related
  const [current_grade_subject, setCurrentGradeSubject] = useState(null)
  // Set Grade Modal
  const [modal_state, setModalState] = useState(false)
  const handleModalClose = () => setModalState(false)
  const handleModalShow = () => setModalState(true)

  const [delete_modal, setDeleteModal] = useState(false)
  // On created
  useEffect(() => {
    gradeSubjectServices.loadGradeSubjects(dispatch)
  }, [dispatch])

  const showNewGradeSubject = () => {
    setCurrentGradeSubject(null)
    handleModalShow()
  }

  const showEditGradeSubject = (gs_id) => {
    setCurrentGradeSubject(gs_id)
    handleModalShow()
  }

  const deleteThisGradeSubjectConfirm = (id) => {
    setDeleteModal(true)
    setCurrentGradeSubject(id)
  }

  const deleteThisGradeSubject = () => {
    gradeSubjectServices.deleteGradeSubject(dispatch, {id : current_grade_subject}).then(() => {
      addToast('Grade Subject deleted successfully', {appearance : 'success'})
      setCurrentGradeSubject(null)
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
      setCurrentGradeSubject(null)
    })
  }

  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4 className="card-title">My Grade Subjects</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                    { (userType==='A') ? (
                      <button onClick={showNewGradeSubject} type="button" className="btn btn-primary">Create</button>
                    ) : '' }
                    </div>
                  </div>

                  <Modal centered show={modal_state} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>{current_grade_subject ? 'Update' : 'New'} Grade Subject</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetGradeSubject grade_subject_id={current_grade_subject} onHide={() => handleModalClose()} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-light" onClick={handleModalClose}>Close</button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={() => deleteThisGradeSubject()} />

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>GRADE</th>
                          <th>SUBJECT</th>
                          { (userType==='A') ? (<th>REMARKS</th> ) : '' }
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        grade_subjects.map(lgrade_subject => {
                          return <tr key={lgrade_subject._id}>
                            <td className="text-bold-500">{lgrade_subject.grade.name}</td>
                            <td className="text-bold-500">{lgrade_subject.subject.name}</td>
                            { (userType==='A') ? ( <td className="text-bold-500">{lgrade_subject.remarks}</td> ) : '' }
                            <td className="text-bold-500">
                              <div className="buttons">
                                <Link to={`gradesubject/${lgrade_subject._id}`}><button className="btn btn-primary">Details</button></Link>
                                { (userType==='A') ? (
                                  <div style={{float : 'left'}}>
                                    <button onClick={() => showEditGradeSubject(lgrade_subject._id)} className="btn btn-warning">Edit</button>
                                    <button onClick={() => deleteThisGradeSubjectConfirm(lgrade_subject._id)} className="btn btn-danger">Delete</button>
                                  </div> ) : ''
                                }
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>          
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default GradeSubject