import LoadingIcon from '../../assets/images/icons/loading.svg'

const Loading = () => {
	return (
		<div className="row">
  		<div className="col-12 text-center">
  			<img src={LoadingIcon} />
  		</div>
  	</div>
	)
}

export default Loading