// This is the list of all teachers
// import {Link} from 'react-router-dom'
// import { useParams } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// import {useToasts} from 'react-toast-notifications'
import PageHeading from '../../components/PageHeading'
import userServices from '../../services/user.service'
import attendanceServices from '../../services/attendance.service'
import { userType } from '../../utils'
import {formatDate} from '../../assets/js/common'
const dayjs = require('dayjs')

const Attendance = () => {
  
  const dispatch = useDispatch()
  // const {addToast} = useToasts()
  // Attendance State
  const attendanceState = useSelector(state => state.attendance)
  const attendances = attendanceState.attendances
  // User State
  const userState = useSelector(state => state.user)
  const teachers = userState.teachers
  const students = userState.students
  // Local States
  const [filter, setFilter] = useState({
    from_date : null, to_date : null, teacher : null, student : null
  })

  // Created
  useEffect(() => {
    userServices.loadStudents(dispatch)
    userServices.loadTeachers(dispatch)
  }, [dispatch])
  // Created + filter change
  useEffect(() => {
    attendanceServices.loadAttendances(dispatch, {filter : {...filter}}) // Load attendances of current student logged in
  }, [dispatch, filter])

  const updateFilter = (key, val) => {
    let new_state = {...filter}
    if(val === ''){
      val = null
    }
    new_state[key] = val
    setFilter(new_state)
  }

  const updateFromDate = (val) => {
    const from_date = dayjs(val, "DD-MM-YYYY").toDate()
    updateFilter('from_date', from_date)
  }

  const updateToDate = (val) => {
    const to_date = dayjs(val, "DD-MM-YYYY").set('hour', 23).set('minute', 59).set('second', 59).toDate()
    updateFilter('to_date', to_date)
  }
  
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4 className="card-title">All attendance records</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <Form.Group>
                        <Form.Label>Filter Date From</Form.Label>
                        <Form.Control type="date" onChange={(e) => updateFromDate(e.target.value)}></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-3">
                      <Form.Group>
                        <Form.Label>Filter Date To</Form.Label>
                        <Form.Control type="date" onChange={(e) => updateToDate(e.target.value)}></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-3">
                      <Form.Group>
                        <Form.Label>Tutor</Form.Label>
                        <Form.Control as="select" defaultValue={filter.teacher} onChange={(e) => updateFilter('teacher', e.target.value)}>
                          <option value=''>Select</option>
                          {
                            teachers.map(lteacher => {
                              return <option key={lteacher._id} value={lteacher._id} >{lteacher.name}</option>
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </div>
                    {userType === 'A' ? (
                    <div className="col-3">
                      <Form.Group>
                        <Form.Label>Student</Form.Label>
                        <Form.Control as="select" defaultValue={filter.student} onChange={(e) => updateFilter('student', e.target.value)}>
                          <option value='' >Select</option>
                          {
                            students.map(lstudent => {
                              return <option key={lstudent._id} value={lstudent._id} >{lstudent.name}</option>
                            })
                          }
                        </Form.Control>
                      </Form.Group>
                    </div>
                    ) : '' }
                    <div className="col-4"></div>
                  </div>
                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>TUTOR</th>
                          <th>STUDENT</th>
                          <th>CREATED AT</th>
                          <th>MARKED AT</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                      {
                        attendances.map((attendance,i) => {                          
                          return <tr key={attendance._id}>
                            <td className="text-bold-500">{i + 1}</td>
                            <td className="text-bold-500">{attendance.created_by.name}</td>
                            <td className="text-bold-500">{attendance.student.name}</td>
                            <td className="text-bold-500">{formatDate(attendance.created_at)}</td>
                            <td className="text-bold-500">{attendance.marked_at ? formatDate(attendance.marked_at) : ''}</td>
                           
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Attendance