// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})

const {userToken} = require('../utils')

//Creating a new grade
function loadChats(dispatch, {filter}){
	let query_arr = []
	for(let key in filter){
		query_arr.push(`${key}=${filter[key]}`)
	}
	const query_str = query_arr.join('&')
	return $http.get(`chat?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_CHATS',
      		payload : res.data
    	})
	})
}

function loadIncomingChats(dispatch, {filter}){
	let query_arr = []
	for(let key in filter){
		query_arr.push(`${key}=${filter[key]}`)
	}
	const query_str = query_arr.join('&')
	return $http.get(`chat/incoming?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_INCOMING_CHATS',
      		payload : res.data
    	})
	})
}

//Creating a new grade
function createMessage(dispatch, {data}){
	return $http.post(`chat`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_CHAT',
      		payload : res.data
    	})
	})
}

const chatServices = {
	loadChats, loadIncomingChats, createMessage
}

export default chatServices