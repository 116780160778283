import {Modal, Button} from 'react-bootstrap'
import {useState, useEffect} from 'react'

const Confirm = ({title, message, modal_state, onSuccess, onClose}) => {
  const [toggle_modal, setToggleModal] = useState(modal_state)

  // Watcher for modal state
  useEffect(() => {
    setToggleModal(modal_state)
  }, [modal_state])

  const closeModal  = () => {
    setToggleModal(false)
    onClose(false)
  }

  const confirmModal = () => {
    setToggleModal(false)
    onClose(false)
    onSuccess()
  }
  return (
    <Modal centered show={toggle_modal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => closeModal()} variant="danger">No</Button>
        <Button onClick={() => confirmModal()} variant="success">Yes</Button>
      </Modal.Footer>
    </Modal> 
  )
}

export default Confirm