// This is the list of all teachers
import {Modal} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useToasts } from 'react-toast-notifications';
import {Link} from 'react-router-dom'
import PageHeading from '../../components/PageHeading'
import SetMaterial from '../../components/Material/SetMaterial'
import materialServices from '../../services/material.service'
import Confirm from '../../components/General/Confirm'
import { userType } from '../../utils'

const Material = () => {
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const materialState = useSelector(state => state.material)
  const materials = materialState.materials
  // Grade related
  const [current_material, setCurrentMaterial] = useState(null)
  // Set Grade Modal
  const [modal_state, setModalState] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  const handleModalClose = () => setModalState(false)
  const handleModalShow = () => setModalState(true)

  // On created
  useEffect(() => {
    materialServices.loadMaterials(dispatch)
  }, [dispatch])

  const showNewMaterial = () => {
    setCurrentMaterial(_ => null)
    handleModalShow()
  }

  const showEditGradeSubject = (gs_id) => {
    setCurrentMaterial(gs_id)
    handleModalShow()
  }

  const deleteThisMaterialConfirm = (id) => {
    setDeleteModal(true)
    setCurrentMaterial(id)
  }

  const deleteThisMaterial = () => {
    materialServices.deleteMaterial(dispatch, {id : current_material}).then(() => {
      addToast('Material deleted successfully', {appearance : 'success'})
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4 className="card-title">My Shared Materials</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                    { ((userType==='T') || (userType==='S')) ? (
                      <button onClick={showNewMaterial} type="button" className="btn btn-primary">Create</button>
                    ) : '' }
                    </div>
                  </div>

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={deleteThisMaterial} />

                  <Modal centered show={modal_state} onHide={handleModalClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{current_material ? 'Update' : 'Share'} Material</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetMaterial material_id={current_material} onHide={() => handleModalClose()} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-primary" onClick={handleModalClose}>Close</button>
                    </Modal.Footer>
                  </Modal>
                  
                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>TITLE</th>
                          <th>SHARED BY</th>
                          <th>SHARED TO</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        materials.map(lmaterial => {
                          return <tr key={lmaterial._id}>
                            <td className="text-bold-500">{lmaterial.title}</td>
                            <td className="text-bold-500">{lmaterial.from.name}</td>
                            <td className="text-bold-500">{lmaterial.to.name}</td>
                            <td className="text-bold-500">
                              <div className="buttons">
                                <Link to={`material/${lmaterial._id}`}><button className="btn btn-primary">Details</button></Link>
                                { ((userType==='T') || (userType==='S')) ? (
                                  <div style={{float : 'left'}}>
                                    <button onClick={() => showEditGradeSubject(lmaterial._id)} className="btn btn-warning">Edit</button>
                                    <button onClick={() => deleteThisMaterialConfirm(lmaterial._id)} className="btn btn-danger">Delete</button>
                                  </div> ) : ''
                                }
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>          
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Material