// This is the list of all teachers
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
// import {Link} from 'react-router-dom'
import { useToasts } from "react-toast-notifications";
import PageHeading from "../../components/PageHeading";
import SetCoupon from "../../components/Coupon/SetCoupon";
import Confirm from "../../components/General/Confirm";
import { formatDate } from "../../assets/js/common";
import { useSelector, useDispatch } from "react-redux";
import couponServices from "../../services/coupon.service";

const Coupon = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  // On created
  useEffect(() => {
    couponServices.loadCoupons(dispatch);
  }, [dispatch]);

  // Coupon related
  const couponState = useSelector((state) => state.coupon);
  const coupons = couponState.coupons;
  const [current_coupon, showCurrentCoupon] = useState(null);
  //   const [isLoading, setLoading] = useState(false);
  // Set Coupon Modal
  const [modal_state, setModalState] = useState(false);
  const handleModalClose = () => setModalState(false);
  const handleModalShow = () => setModalState(true);
  const [delete_modal, setDeleteModal] = useState(false);

  const showNewCoupon = () => {
    showCurrentCoupon(null);
    handleModalShow();
  };

  const showEditCoupon = (coupon) => {
    showCurrentCoupon(coupon);
    handleModalShow();
  };

  const deleteThisCouponConfirm = (id) => {
    showCurrentCoupon(id);
    setDeleteModal(true);
  };

  const deleteThisCoupon = () => {
    couponServices
      .deleteCoupon(dispatch, { id: current_coupon })
      .then(() => {
        addToast("Coupon deleted successfully", { appearance: "success" });
        showCurrentCoupon(null);
      })
      .catch((err) => {
        addToast(err.response.data, { appearance: "error" });
        showCurrentCoupon(null);
      });
  };

  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="card-title">Coupons</h4>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <button
                        onClick={showNewCoupon}
                        type="button"
                        className="btn btn-primary"
                      >
                        Create
                      </button>
                    </div>
                  </div>

                  <Modal centered show={modal_state}>
                    <Modal.Header>
                      <Modal.Title>Set coupon</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetCoupon
                        coupon_id={current_coupon}
                        onHide={() => handleModalClose()}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-light"
                        onClick={handleModalClose}
                      >
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete*/}
                  <Confirm
                    title="Confirm Delete"
                    message="Are you sure want to delete this grade ?"
                    modal_state={delete_modal}
                    onClose={(val) => setDeleteModal(false)}
                    onSuccess={() => deleteThisCoupon()}
                  />

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>TYPE</th>
                          <th>CODE</th>
                          <th>AMOUNT</th>
                          <th>PERCENTAGE</th>
                          <th>MAX USAGE</th>
                          <th>CREATED AT</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons.map((coupon) => {
                          return (
                            <tr key={coupon._id}>
                              {console.log(coupon)}
                              <td className="text-bold-500">
                                {coupon.type === "A" ? "Amount" : "Percentage"}
                              </td>
                              <td className="text-bold-500">{coupon.code}</td>
                              <td className="text-bold-500">
                                {coupon.amount !== null ? coupon.amount : "0"}
                              </td>
                              <td className="text-bold-500">
                                {coupon.percentage !== null
                                  ? coupon.percentage
                                  : "0"}
                              </td>
                              <td className="text-bold-500">
                                {coupon.max_usage}
                              </td>
                              <td className="text-bold-500">
                                {formatDate(coupon.created_at)}
                              </td>
                              <td className="text-bold-500">
                                <div className="buttons">
                                  <button
                                    onClick={() => showEditCoupon(coupon._id)}
                                    className="btn btn-warning"
                                  >
                                    Edit
                                  </button>
                                  <button
                                    onClick={() =>
                                      deleteThisCouponConfirm(coupon._id)
                                    }
                                    className="btn btn-danger"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Coupon;
