const Footer = () => {
	return (
		<footer>
            <div className="footer clearfix mb-0 text-muted">
                <div className="float-start">
                    <p>2021 &copy; Access2successonline</p>
                </div>
                <div className="float-end">
                    <p>Crafted with <span className="text-danger"><i className="bi bi-heart"></i></span> by <a
                            href="http://in.linkedin.com/kushalpoddar">Kushal Poddar</a></p>
                </div>
            </div>
        </footer>
	)
}
export default Footer