const initialState = {
	attendances : [],
	current_attendance : null
}

const attendanceReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_ATTENDANCES':
		new_state = {
			...state,
			attendances : action.payload
		}
		break;

		case 'SET_ATTENDANCE':
		new_state = {
			...state,
			current_attendance : action.payload
		}
		break;

		case 'ADD_ATTENDANCE':
		new_state = {
			...state,
			attendances : [action.payload, ...state.attendances]
		}
		break;

		case 'UPDATE_ATTENDANCE':
		const rec_index = state.attendances.findIndex(obj => obj._id === action.payload._id)
		new_state = {
			...state,
			attendances : [...state.attendances.slice(0, rec_index), action.payload, ...state.attendances.slice(rec_index+1, state.attendances.length)]
		}
		break;

		

		case 'REMOVE_ATTENDANCE':
		new_state = {
			...state,
			attendances : state.attendances.filter(obj => obj._id !== action.payload._id)
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default attendanceReducer