import {useState, useEffect} from 'react'
import {Form, InputGroup} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import userServices from '../../services/user.service'
import gradeSubjectServices from '../../services/grade_subject.service'
import {useToasts} from 'react-toast-notifications'
import {useHistory} from 'react-router-dom'
// Import React Select
import Select from 'react-select'
// Import React FilePond
import { FilePond } from 'react-filepond'
// import SetTeacherTimeTable from '../../components/Teacher/SetTimeTable'
import SetTutorAvailabilityTable from '../../components/TutorAvailability/SetTutorAvailabilityTable'

import {baseURL} from '../../assets/js/config'
import ViewFile from '../../components/General/ViewFile'

const SetUser = ({type, id}) => {
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const userState = useSelector(state => state.user)

  const history = useHistory()
  const gradeSubjectState = useSelector(state => state.grade_subject)
  const grade_subjects = gradeSubjectState.grade_subjects

  const teachers = userState.teachers

  const [user, setUser] = useState({
    name : '',
    mobile : '',
    country_code : '',
    type : type,
    email : '',
    username : '',
    password : '',
    password1 : '',
    selected_grade_subjects : [],
    selected_teachers : [],
    address : '',
    remarks : '',
    timetable : [],
    availability : null,
    files : [],
    editfiles : [],
    timezone : "",
    status : true
  })
  const [toggle_password, setTogglePassword] = useState(false)

  // On created & new id
  useEffect(() => {

    // Loading grade subjects
    gradeSubjectServices.loadGradeSubjects(dispatch).catch(err => {
      addToast(err.response.data, { appearance : 'error'})
    })

    // Loading teachers
    userServices.loadTeachers(dispatch).catch(err => {
      addToast(err.response.data, { appearance : 'error'})
    })

    if(id){
      // Removing previous user
      userServices.emptyUser(dispatch)
      //Loading details of the user
      userServices.loadUser(dispatch, {id}).then((res) => {
        let new_user = {}
        let current_user_local = res.data
        for(let key in user){
          let new_val = current_user_local[key]
          if(key === 'selected_grade_subjects'){
            new_user[key] = current_user_local['grade_subjects'].map(obj => { return {value : obj._id, label : `${obj.grade.name} - ${obj.subject.name}`}})
          }else if(key === 'selected_teachers'){
            new_user[key] = current_user_local['teachers'].map(obj => { return {value : obj._id, label : `${obj.name}`}})
          }else if((key === 'password' || key === 'password1')){
            new_user[key] = ''
          }else if(key === 'files'){
            new_user['editfiles'] = current_user_local.files.map(file_url => {
              return {
                file : file_url,
                is_deleted : false
              }
            })
            
          }else if(key === 'editfiles'){
            continue
          }else{
            new_user[key] = new_val
          }
          
        }

        setUser(new_user)
      }).catch(err => {
        addToast(err.response.data, { appearance : 'error' })
      })
    }
  }, [dispatch, id, addToast])


  const [files, setFiles] = useState([])

  const toggleChangePassword = () => {
    setTogglePassword(!toggle_password)
  }

  const updateUserVals = (key, val) => {
    let new_state = { ...user }
    new_state[key] = val
    setUser(new_state)
  }

  function proceed(){
    let teacher_data = { 
      ...user,
      grade_subjects : user.selected_grade_subjects.map(obj => obj.value), 
      teachers : user.selected_teachers.map(obj => obj.value), 
      files
    }

    if(id){
      teacher_data['toggle_password'] = toggle_password
    }

    delete teacher_data.selected_grade_subjects
    delete teacher_data.selected_teachers
    delete teacher_data.password1

    const next_route_to = (type === 'S') ? '/student' : '/teacher'
    const pref_label = (type === 'S') ? 'Student' : 'Teacher'
    if(id){
      // Updating the user
      userServices.updateUser(dispatch, {data : teacher_data, id}).then(() => {
        addToast(`${pref_label} updated successfully`, { appearance : 'success' })
        history.push(next_route_to)
      }).catch(err => {
        console.log(err.response)
        addToast(err.response.data, { appearance : 'error' })
      })
    }else{
      // Creating the user
      if(user.password === user.password1){
        userServices.createUser(dispatch, {data : teacher_data}).then(() => {
          addToast(`${pref_label} created successfully`, { appearance : 'success' })
          history.push(next_route_to)
        }).catch(err => {
          addToast(err.response.data, { appearance : 'error' })
        })
      }else{
        addToast('Both passwords should be same', { appearance : 'error' })
      }
    }
    
  }

  const filepondServer = {
    url : `${baseURL}`,
    process: {
      url: `upload`,
      method: 'POST',
      timeout: 7000,
      onload : (file_arr) => {
        // This return object is a string.
        file_arr = JSON.parse(file_arr)
        setFiles(old_files => [...old_files, ...file_arr])
      } 
    } 
  }

  const removeFile = (file_i) => {
    let new_user = Object.assign({}, user)
    new_user['editfiles'][file_i].is_deleted = true
    setUser(new_user)
  }
  return (
    <div className="form">
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="first-name-column">Name</label>
            <input type="text" className="form-control" value={user.name} onChange={(e) => updateUserVals('name', e.target.value)} />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="last-name-column">Country Code</label>
            <InputGroup>
              <InputGroup.Text>+</InputGroup.Text>
              <input type="text" className="form-control" value={user.country_code} onChange={(e) => updateUserVals('country_code', e.target.value)} />
            </InputGroup>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label htmlFor="last-name-column">Mobile</label>
            <input type="text" className="form-control" value={user.mobile} onChange={(e) => updateUserVals('mobile', e.target.value)} />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" value={user.email} onChange={(e) => updateUserVals('email', e.target.value)} />
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Username</label>
            <input type="text" className="form-control" value={user.username} onChange={(e) => updateUserVals('username', e.target.value)} />
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Timezone</label>
            <Form.Control as="select" value={user.timezone} onChange={(e) => updateUserVals('timezone', e.target.value)}>
              <option value="" hidden>Select</option>
              <option value="IST">IST</option>
              <option value="EST">EST</option>
              <option value="CST">CST</option>
              <option value="MST">MST</option>
              <option value="PST">PST</option>
            </Form.Control>
          </div>
        </div>

        { type === 'T' ? (
          <div className="col-md-6 col-12">
            <div className="form-group">
              <label>Grade Subjects</label>
              <Select value={user.selected_grade_subjects} onChange={(e) => updateUserVals('selected_grade_subjects', [...e])} isMulti className="basic-multi-select" classNamePrefix="select" options={grade_subjects.map(lgs => { return { value : lgs._id, label : `${lgs.grade.name} - ${lgs.subject.name}`}})} />
            </div>
          </div>
        ) : ''}
    
        { id ? (
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Change Password</label><br />
            <input type="checkbox" className="form-check-input" onChange={() => toggleChangePassword()} />
          </div>
        </div> ) : '' }

        {
        // Show only if entry form or edit form has change password clicked
        ((!id) || (id && toggle_password)) ? (
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" value={user.password} onChange={(e) => updateUserVals('password', e.target.value)} />
          </div>
        </div>
        ) : '' }

        {
        // Show only if entry form or edit form has change password clicked
        ((!id) || (id && toggle_password)) ? (
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>Re-enter Password</label>
            <input type="password" className="form-control" value={user.password1} onChange={(e) => updateUserVals('password1', e.target.value)} />
          </div>
        </div>
        ) : '' }
        <div className="col-12">
          <div className="form-group">
            <label>Address</label>
            <textarea className="form-control" value={user.address} onChange={(e) => updateUserVals('address', e.target.value)} />
          </div>
        </div>

        <div className="col-12">
          <div className="form-group">
            <label>Remarks</label>
            <textarea className="form-control" value={user.remarks} onChange={(e) => updateUserVals('remarks', e.target.value)} />
          </div>
        </div>

        { type === 'S' ? (
        <div className="col-12">
          <div className="form-group">
            <label>Assign Teachers</label>
            <Select value={user.selected_teachers} onChange={(e) => updateUserVals('selected_teachers', [...e])} isMulti className="basic-multi-select" classNamePrefix="select" options={teachers.map(lteacher => { return { value : lteacher._id, label : lteacher.name }})} />
          </div>
        </div>
        ) : ''}


        { (user.editfiles && user.editfiles.filter(file_obj => !file_obj.is_deleted).length) ? (
        <div>
          <h3 className="text-center">Uploaded Files</h3>
          <div className="row">
            { user.editfiles.filter(file_obj => !file_obj.is_deleted).map((file, file_i) => {
              return (
              <div key={`FILE_${file_i}`} className="col-4">
                <ViewFile show_remove={true} url={file.file} onRemove={() => removeFile(file_i)} />
              </div>
            ) }) }
          </div>
        </div>) : ''}

        <h3 className="text-center">New Files</h3>
        <FilePond allowMultiple={true} maxFiles={5} name="files" server={filepondServer}
        labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>' />     

      </div>

      <h3 className="text-center">Availability (In Tutor's Timezone)</h3>
      <SetTutorAvailabilityTable availability={user.availability} onchange={(val) => updateUserVals('availability', val)} />
      {/*<SetTeacherTimeTable timetable={user.timetable} onchange={(val) => updateUserVals('timetable', val)} />*/}

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label>User Status</label>
            <Form.Control as="select" value={user.status} onChange={(e) => updateUserVals('status', e.target.value)}>
              <option value="true" >Active</option>
              <option value="false" >Non-Active</option>
            </Form.Control>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center">
        <button onClick={proceed} type="button" className="btn btn-primary me-1 mb-1">Submit</button>
      </div>
    </div>
  )
}

export default SetUser