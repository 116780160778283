import {Form} from 'react-bootstrap'
import {useState, useEffect} from 'react'
import {useToasts} from 'react-toast-notifications'
import chatServices from '../../services/chat.service'
import {useSelector, useDispatch} from 'react-redux'
import {formatDate} from '../../assets/js/common'
import { userType } from '../../utils'

const ShowChats = ({user_id}) => {
  const dispatch = useDispatch()
  const [new_message, setNewMessage] = useState('')
  const [is_sms, setIsSMS] = useState(false)

  const chatState = useSelector(state => state.chat)
  const chats = chatState.chats

  const {addToast} = useToasts()
  // Watcher for user id
  useEffect(() => {
    chatServices.loadChats(dispatch, {
      filter : {
        user_id
      }
    }).catch(err => {
      addToast(err.response.data, {appearance : 'error'})
    })
  }, [user_id, dispatch, addToast])

  const addNewMessage = () => {
    chatServices.createMessage(dispatch, {
      data : {
        user : user_id,
        content : new_message,
        is_sms
      }
    }).then(() => {
      addToast('Message sent', {appearance : 'success'})
      setNewMessage("")
    }).catch(err => {
      addToast(err.response.data, {appearance : 'error'})
    })
  }

  const SMSCheckboxChange = (event) => {
    setIsSMS(event.target.checked)
  }
  return (
    <div className="row">
      { chats.map((msg) => {
          return (
            <div key={msg._id} className="col-12">
              <p className="mb-0 mt-2"><b>{msg.from.name}</b> - {msg.content}</p>
              <small style={{fontSize : '13px'}}>{formatDate(msg.created_at)}</small>
            </div>
          )      
        }) 
      }

      <hr />
      <div className="col-12">
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>New message</Form.Label>
          <Form.Control value={new_message} onChange={(e) => setNewMessage(e.target.value)} as="textarea" rows={3} />
        </Form.Group>
        { userType === 'T' ? 
        <Form.Check 
          onChange={SMSCheckboxChange}
          label="Send message as an SMS"
        /> : null }
        <button type="button" className="btn btn-primary" onClick={() => addNewMessage()} >Send</button>
      </div>
    </div>
  )
}

export default ShowChats