const initialState = {
	current_material : null,
	materials : [],
}

const materialsReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_MATERIALS':
		new_state = {
			...state,
			materials : action.payload
		}
		break;

		case 'SET_MATERIAL':
		new_state = {
			...state,
			current_material : action.payload
		}
		break;
		case 'ADD_MATERIAL':
		new_state = {
			...state,
			materials : state.materials.concat(action.payload)
		}
		break;
		case 'REMOVE_MATERIAL':
		new_state = {
			...state,
			materials : [...state.materials.filter(material => material._id !== action.payload._id)]
		}
		break;
		default:
		new_state = state
	}
	return new_state
}

export default materialsReducer