const initialState = {
	schedules : [],
	schedule : null,
}

const schedulesReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_SCHEDULE':
		new_state = {
			...state,
			schedule : action.payload
		}
		break;

		case 'SET_SCHEDULES':
		new_state = {
			...state,
			schedules : action.payload
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default schedulesReducer