// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})

const {userToken} = require('../utils')

// Loading attendance
function loadAttendances(dispatch, {filter}){
	let query_arr = []
	if(filter){
		for(let key in filter){
			if((filter[key]!==null) && (filter[key] !== undefined)){
				query_arr.push(`${key}=${filter[key]}`)	
			}
		}
	}
	let query_str = query_arr.join('&')
	return $http.get(`attendance?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_ATTENDANCES',
      		payload : res.data
    	})
	})
}

// Updating attendance
function createAttendance(dispatch, {data}){
	return $http.post(`attendance`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_ATTENDANCE',
      		payload : res.data
    	})
	})
}

// Updating attendance
function markAttendance(dispatch, {id}){
	return $http.put(`attendance/${id}/mark`, {}, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'UPDATE_ATTENDANCE',
      		payload : res.data
    	})
	})
}

// Deleting attendance
function deleteAttendance(dispatch, {id}){
	return $http.delete(`attendance/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_ATTENDANCE',
      		payload : { _id : id }
    	})
	})
}


const attendanceServices = {
	loadAttendances, createAttendance, deleteAttendance, markAttendance
}

export default attendanceServices