const initialState = {
	current_grade : null,
	grades : [],
}

const gradesReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_GRADES':
		new_state = {
			...state,
			grades : action.payload
		}
		break;

		case 'SET_GRADE':
		new_state = {
			...state,
			current_grade : action.payload
		}
		break;
		case 'ADD_GRADE':
		new_state = {
			...state,
			grades : state.grades.concat(action.payload)
		}
		break;
		case 'REMOVE_GRADE':
		new_state = {
			...state,
			grades : [...state.grades.filter(grade => grade._id !== action.payload._id)]
		}
		break;
		default:
		new_state = state
	}
	return new_state
}

export default gradesReducer