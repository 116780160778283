import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {Spinner} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import gradeSubjectServices from '../../services/grade_subject.service'
import ViewFile from '../../components/General/ViewFile'

const ViewGradeSubject = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const gradeSubjectState = useSelector(state => state.grade_subject)
  const grade_subject = gradeSubjectState.current_grade_subject

  // On created
  useEffect(() => {
    gradeSubjectServices.loadGradeSubject(dispatch, {id})
  }, [id, dispatch])

  return(
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
        { grade_subject ? (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Grade Subject Details</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <p><b>Name</b> - {grade_subject.grade.name} - {grade_subject.subject.name}</p>
                
                <p><b>Remarks</b> - {grade_subject.remarks}</p>

                <h3 className="text-center">Files</h3>
                <div className="row">
                  { grade_subject.files.map((file, file_i) => {
                    return (
                    <div className="col-12 col-lg-4 col-md-6" key={`FILE_${file_i}`}>
                      <ViewFile url={file} />
                    </div>
                  ) }) }
                </div>
              </div>
            </div>
          </div>
         ) : <Spinner className="btn-center" animation="border" /> }
        </div>
      </div>
    </section>
  )
}

export default ViewGradeSubject