const initialState = {
	current_subject : null,
	subjects : [],
}

const subjectsReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_SUBJECTS':
		new_state = {
			...state,
			subjects : action.payload
		}
		break;

		case 'SET_SUBJECT':
		new_state = {
			...state,
			current_subject : action.payload
		}
		break;

		case 'ADD_SUBJECT':
		new_state = {
			...state,
			subjects : state.subjects.concat(action.payload)
		}
		break;

		case 'REMOVE_SUBJECT':
		new_state = {
			...state,
			subjects : [...state.subjects.filter(subject => subject._id !== action.payload._id)]
		}
		break;
		
		default:
		new_state = state
	}
	return new_state
}

export default subjectsReducer