import { useState, useEffect } from "react"
import {Form, Button, Spinner} from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from "react-redux"
import gradeServices from '../../services/grade.service'

const SetGrade = ({grade_id, onHide}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const gradeState = useSelector(state => state.grade)

  const current_grade = gradeState.current_grade
  const [isLoading, setLoading] = useState(false)
  const [grade_name, setGradeName] = useState('')
  const [grade_remarks, setGradeRemarks] = useState('')

  const proceed = () => {
    let grade_data = {
      name : grade_name,
      remarks : grade_remarks
    }
    if(grade_id){
      // If update
      gradeServices.updateGrade(dispatch, { id : grade_id, data : grade_data }).then(() => {
        addToast('Grade updated successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
      })
    }else{
      // If entry
      gradeServices.createGrade(dispatch, { data : grade_data }).then(() => {
        addToast('Grade created successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
      })
    }
  }

  // Watching for new grade id
  useEffect(() => {
    if(grade_id){
      setLoading(true)
      gradeServices.loadGrade(dispatch, {id:grade_id}).then(() => {
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        addToast(err.response.data, { appearance: 'error' })
      })
    }else{
      gradeServices.emptyGrade(dispatch)    
    }
  }, [grade_id, dispatch, addToast])

  // Watching for current loaded grade
  useEffect(() => {
    if(current_grade && grade_id){
      setGradeName(current_grade.name)
      setGradeRemarks(current_grade.remarks)
    }
  }, [current_grade])

  return (
    <div>
      { isLoading ? <Spinner className="btn-center" animation="border" /> : (
      <div>
        <Form.Group>
          <Form.Label>Grade Name</Form.Label>
          <Form.Control value={grade_name} onChange={(e) => setGradeName(e.target.value)} type="text" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Remarks</Form.Label>
          <Form.Control value={grade_remarks} onChange={(e) => setGradeRemarks(e.target.value)} rows="3" as="textarea" />
        </Form.Group>

        <Button className="btn-center" onClick={proceed}>{grade_id ? 'Update Grade' : 'Save Grade'}</Button>
      </div> ) 
      }
    </div>
  )
}

export default SetGrade