const initialState = {
	profile : null,
}

const gradesReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_AUTH':
		const profile = action.payload
		if(profile){
			localStorage.setItem('name', profile.name)
			localStorage.setItem('token', profile.token)
			localStorage.setItem('type', profile.type)
		}
		new_state = {
			...state,
			profile
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default gradesReducer