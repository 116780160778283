// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})

const {userToken} = require('../utils')

//Loading schedule
function loadReminders(dispatch, payload){
	let query_arr = []
	// if(filter){
	// 	for(let key in filter){
	// 		query_arr.push(`${key}=${filter[key]}`)
	// 	}
	// }
	let query_str = query_arr.join('&')
	return $http.get(`reminder?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_REMINDERS',
      		payload : res.data
    	})
	})
}

const reminderServices = {
	loadReminders
}

export default reminderServices