export const TIMEZONES = {
	'IST' : {
		style : {
			color: '#ff1100'
		}
	},
	'EST' : {
		style : {
			color: '#e6e200'
		}
	},
	'CST' : {
		style : {
			color: '#2fff00'
		}
	},
	'MST' : {
		style : {
			color: '#002ed4'
		}
	},
	'PST' : {
		style : {
			color: '#9000ff'
		}
	} 
}