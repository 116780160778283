// This is the list of attendance of a student
// import {Link} from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Modal, Button, Form } from 'react-bootstrap'
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useToasts} from 'react-toast-notifications'
import PageHeading from '../../components/PageHeading'
import userServices from '../../services/user.service'
import attendanceServices from '../../services/attendance.service'
import { userType } from '../../utils'
import {formatDate} from '../../assets/js/common'
const dayjs = require('dayjs')

const StudentAttendance = () => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  // User State
  const userState = useSelector(state => state.user)
  const student = userState.current_user
  
  // Attendance State
  const attendanceState = useSelector(state => state.attendance)
  const attendances = attendanceState.attendances

  // Local States
  const [show_delete_confirm_modal, setToggleDeleteConfirmModal] = useState(false)
  const [show_create_confirm_modal, setToggleCreateConfirmModal] = useState(false)
  const [selected_attendance, setSelectedAttendance] = useState(null)
  const [filter, setFilter] = useState({
    from_date : null, to_date : null
  })

  // Created
  useEffect(() => {
    userServices.loadUser(dispatch, {id})
  }, [dispatch, id])

  // Created + filter change
  useEffect(() => {
    attendanceServices.loadAttendances(dispatch, {filter : {student : id, ...filter}})
  }, [dispatch, filter, id])
  
  const confirmDelete = (id) => {
    setSelectedAttendance(id)
    setToggleDeleteConfirmModal(true)
  }

  const updateFilter = (key, val) => {
    let new_state = {...filter}
    new_state[key] = val
    setFilter(new_state)
  }

  const updateFromDate = (val) => {
    const from_date = dayjs(val, "DD-MM-YYYY").toDate()
    updateFilter('from_date', from_date)
  }

  const updateToDate = (val) => {
    const to_date = dayjs(val, "DD-MM-YYYY").set('hour', 23).set('minute', 59).set('second', 59).toDate()
    updateFilter('to_date', to_date)
  }

  const deleteThisAttendance = () => {
    //Switching off the modal
    setToggleDeleteConfirmModal(false)
    attendanceServices.deleteAttendance(dispatch, {id : selected_attendance}).then(() => {
      addToast('Attendance deleted successfully', {appearance : 'success'})
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }

  const createThisAttendance = () => {
    //Switching off the modal
    setToggleCreateConfirmModal(false)
    attendanceServices.createAttendance(dispatch, {
      data : {
        student : id
      }
    }).then(() => {
      addToast('Attendance created', { appearance: 'success' })
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <div className="row">
                    <div className="col-8">
                      { student ? (<h4 className="card-title">{student.name}'s attendance records</h4>) : '' }
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      { (userType==='T') ? (
                        <Form.Group>
                          <Button onClick={() => setToggleCreateConfirmModal(true)} className="btn btn-primary">Create Attendance Now</Button>
                        </Form.Group>
                      ) : '' }
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <Form.Group>
                        <Form.Label>Filter Date From</Form.Label>
                        <Form.Control type="date" onChange={(e) => updateFromDate(e.target.value)}></Form.Control>
                      </Form.Group>
                    </div>
                    <div className="col-4">
                      <Form.Group>
                        <Form.Label>Filter Date To</Form.Label>
                        <Form.Control type="date" onChange={(e) => updateToDate(e.target.value)}></Form.Control>
                      </Form.Group>
                    </div>
                    
                  </div>
                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>CREATED AT</th>
                          <th>MARKED AT</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        attendances.map((attendance,i) => {                          
                          return <tr key={attendance._id}>
                            <td className="text-bold-500">{i + 1}</td>
                            <td className="text-bold-500">{formatDate(attendance.created_at)}</td>
                            <td className="text-bold-500">{attendance.marked_at ? formatDate(attendance.marked_at) : ''}</td>
                            <td className="text-bold-500">
                              <div className="buttons">
                                { ((userType==='T') || (userType==='A')) ? (
                                  <div style={{float : 'left'}}>
                                    <button onClick={() => confirmDelete(attendance._id)} className="btn btn-danger">Delete</button>
                                  </div>
                                  ) : ''
                                }
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>

                  {/* Confirm Dialog for Delete*/}
                  <Modal centered show={show_delete_confirm_modal}>
                    <Modal.Header>
                      <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <p>Are you sure want to delete this attendance?</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setToggleDeleteConfirmModal(false)} variant="danger">No</Button>
                      <Button onClick={() => deleteThisAttendance(selected_attendance)} variant="success">Yes</Button>
                    </Modal.Footer>
                  </Modal>

                  {/* Confirm Dialog for Create Attendance*/}
                  <Modal centered show={show_create_confirm_modal}>
                    <Modal.Header>
                      <Modal.Title>Confirm Creation</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <p>Are you sure want to create an attendance?</p>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setToggleCreateConfirmModal(false)} variant="danger">No</Button>
                      <Button onClick={() => createThisAttendance()} variant="success">Yes</Button>
                    </Modal.Footer>
                  </Modal>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default StudentAttendance