const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const formatDate = function (timestamp) {
  return dayjs(timestamp).format("MMMM DD, YYYY, hh:mm:ss a");
};

const formatTime = function (timestamp) {
  return dayjs(timestamp).format("MMMM DD, YYYY");
};

const formatDateFromNow = function (timestamp) {
  return dayjs(timestamp).fromNow();
};

module.exports = {
  formatDate,
  formatTime,
  formatDateFromNow,
};
