import {useHistory} from 'react-router-dom'
import {Form, Button, Spinner} from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import authServices from '../../services/auth.service'

import LogoImage from '../../assets/images/logo/logo.png'

const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [toggle_spinner, setToggleSpinner] = useState(false)
  const changeUsername = (value) => {
    setUsername(value)
  }

  const changePassword = (value) => {
    setPassword(value)
  }

  const proceed = () => {
    // Logging in
    setToggleSpinner(true)
    authServices.loginUser(dispatch, {
      data : {
        username,
        password
      }
    }).then(() => {
      setToggleSpinner(false)
      history.push('/dashboard')
      window.location = window.location.href
    }).catch((err) => {
      setToggleSpinner(false)
      console.log(err)
      addToast(err.response.data, { appearance: 'error' })
    })
  }
  return (
    <div className="container">
      <div style={{height: '100vh'}} className="row">
        <div className="col-11 col-lg-6 col-md-6 m-auto">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <img style={{width: '100%'}} alt="Access2Successonline Logo" src={LogoImage} />
                </div>
              </div>
              
              <Form.Group>
                <Form.Label>Username</Form.Label>
                <Form.Control onChange={(e) => changeUsername(e.target.value)} type="text" />   
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control onChange={(e) => changePassword(e.target.value)} type="password" />
              </Form.Group>

              <Button onClick={() => proceed()} className="btn-center">Login</Button>
              { toggle_spinner ? <Spinner className="btn-center" animation="border" /> : '' }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login