import {useState, useEffect} from 'react'
import {getAllHours} from './utils'
import {TIMEZONES} from '../Common/utils'

//Components
import TimezoneColorLabel from './TimezoneColorLabel'

const ViewUserSchedule = ({schedules, type}) => {
	
	let hours = getAllHours()
	let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

	// Check if day,hour is present in user's current timetable
	function isAvailable(schedule, cell_key){
		return (schedule[cell_key])
	}

	function timezoneLabel(schedule){
		return (type == 'T') ? schedule.student.timezone : schedule.teacher.timezone
	}

	function labelText(schedule){
		if(type == 'T'){
			return `${schedule.student.name}`
		}else if(type == 'S'){
			return `${schedule.teacher.name}` 
		}else if(type == 'B'){
			// if type is both
			return `${schedule.teacher.name} & ${schedule.student.name}` 
		}
	}

	return (
		<>
		<TimezoneColorLabel />
		<div className="table-responsive">
			<table className="table table-lg table-bordered no-padding all-text-center">
				<tbody>
					<tr>
						<th></th>
						{
							days.map((day, day_i) => {
								return <td key={`HEAD_COL_${day_i}`}>{day}</td>
							})
						}
					</tr>
					{
						hours.map((hour, hour_i) => {
							return <tr key={`DAY_${hour_i}`}><td>{hour}</td>
								{	
									days.map((day, day_i) => {
										return (
											<td className={ isAvailable(`${hour_i}-${day_i}`) ? 'green-bg' : 'transparent-bg' } key={`COL_${hour_i}_${day_i}`}>
												{
													// Now iterating on all schedules for current user
													schedules.map(schedule => {
														return isAvailable(schedule.schedule, `${hour_i}-${day_i}`) ? (
															<div key={`COL_${hour_i}_${day_i}_${schedule._id}`} className="my-2 schedule-cell-label" style={{'borderColor' : TIMEZONES[timezoneLabel(schedule)].style.color}}>
															{ labelText(schedule) }
															</div>
														) : null
													})
												}
													
											</td>
										)
									})
								}
							</tr>
						})
					}
				</tbody>
			</table>
		</div>
		</>
	)
}

export default ViewUserSchedule