// This is the list of all teachers
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
// import {Link} from 'react-router-dom'
import { Form, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import PageHeading from "../../components/PageHeading";
import ShowSubscriber from "../../components/Subscriber/ShowSubscriber";
import { useSelector, useDispatch } from "react-redux";
import subscriberServices from "../../services/subscriber.service";
import { formatDate, formatTime } from "../../assets/js/common";

const Subscriber = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [filter, setFilter] = useState({
    name: null,
    subject: null,
    grade: null,
    timezone: null,
  })

  // Created + filter change
  useEffect(() => {
    subscriberServices.loadSubscribers(dispatch, {filter});
 }, [dispatch, filter]);

  const updateFilter = (key, val) => {
    let new_state = { ...filter };
    if (val === "") {
      val = null;
    }
    new_state[key] = val;
    setFilter(new_state);
  };

  // Subscriber related
  const subscriberState = useSelector((state) => state.subscriber);
  const subscribers = subscriberState.subscribers;
  const [current_subscriber, showCurrentSubscriber] = useState(null);
  // Set Subscriber Modal
  const [modal_state, setModalState] = useState(false);
  const handleModalClose = () => setModalState(false);
  const handleModalShow = () => setModalState(true);

  const showSubscriber = (subscriber_id) => {
    if(subscriber_id){
      showCurrentSubscriber(subscriber_id);
      handleModalShow();
    }
    
  };

  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="card-title">Subscribers</h4>
                    </div>
                  </div>

                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Filter Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Subscriber Name"
                          onChange={(e) => updateFilter("name", e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col>
                      <Form.Group>
                        <Form.Label>Filter Subject</Form.Label>
                        <Form.Control
                          as="select"
                          value={filter.subject || ""}
                          onChange={(e) =>
                            updateFilter("subject", e.target.value)
                          }
                        >
                          <option value="">Select Subject</option>
                          {[...Array(10)].map((e, index) => (
                            <option value={index + 1} key={index}>
                              {index + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                    {/* <Col>
                      <Form.Group>
                        <Form.Label>Filter Grade</Form.Label>
                        <Form.Control
                          as="select"
                          value={filter.grade || ""}
                          onChange={(e) =>
                            updateFilter("grade", e.target.value)
                          }
                        >
                          <option value="">Select Grade</option>
                          {[...Array(10)].map((e, index) => (
                            <option value={index + 1} key={index}>
                              {index + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col> */}
                  </Row>

                  <Modal centered show={modal_state}>
                    <Modal.Header>
                      <Modal.Title>Subscriber</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ShowSubscriber
                        id={current_subscriber}
                        onHide={() => handleModalClose()}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-light"
                        onClick={handleModalClose}
                      >
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          <th>MOBILE</th>
                          <th>CHILDREN</th>
                          <th>START DATE</th>
                          <th>SUBSCRIBED AT</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscribers.map((subscriber) => {
                          return (
                            <tr key={subscriber._id}>
                              <td className="text-bold-500">
                                <small>{subscriber.name}</small>
                              </td>
                              <td className="text-bold-500">
                                <small>{subscriber.email}</small>
                              </td>
                              <td className="text-bold-500">
                                <small>{subscriber.mobile}</small>
                              </td>
                              <td className="text-bold-500">
                                <small>{subscriber.children.length}</small>
                              </td>
                              <td className="text-bold-500">
                                <small>{formatTime(subscriber.start_date)}</small>
                              </td>
                              <td className="text-bold-500">
                                <small>{formatDate(subscriber.created_at)}</small>
                              </td>
                              <td className="text-bold-500">
                                <div className="buttons">
                                  <button
                                    onClick={() =>
                                      showSubscriber(subscriber._id)
                                    }
                                    className="btn btn-warning"
                                  >
                                    View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Subscriber;
