// Regarding API
const axios = require("axios");
const { baseURL } = require("../assets/js/config");
const $http = axios.create({
  baseURL,
});
const { userToken } = require("../utils");

//Loading all subscribers
const loadSubscribers = (dispatch, {filter}) => {
  let queryStringArr = []
  for(let filterKey in filter){
    const val = filter[filterKey]
    if(val !== null && val !== undefined){
      queryStringArr.push(`${filterKey}=${encodeURIComponent(filter[filterKey])}`)
    }
  }
  const queryString = queryStringArr.join("&")
  return $http
    .get(`subscriber?${queryString}`, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "SET_SUBSCRIBERS",
        payload: res.data,
      });
    });
};

//Loading a specific SUBSCRIBER
function loadSubscriber(dispatch, { id }) {
  return $http
    .get(`subscriber/${id}`, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      dispatch({
        type: "SET_SUBSCRIBER",
        payload: res.data,
      });
    });
}

//Updating a suscriber
function updateSubscriber(dispatch, data) {
  return $http
    .post(`subscriber/update`, data, {
      headers: {
        "x-auth-token": userToken,
      },
    })
    .then((res) => {
      console.log(res);
      // dispatch({
      //   type: "UPDATE_SUBSCRIBER",
      //   payload: { data: res.data, id },
      // });
    });
}

const subscriberServices = {
  loadSubscribers,
  loadSubscriber,
  updateSubscriber,
};

export default subscriberServices;
