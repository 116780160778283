// import { Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import ViewUserSchedules from '../../components/Schedule/ViewUserSchedule'
import ViewFile from '../../components/General/ViewFile'
import Loading from '../../components/Common/Loading'
import SetTutorAvailabilityTable from '../../components/TutorAvailability/SetTutorAvailabilityTable'

// Services
import userServices from '../../services/user.service'
import scheduleServices from '../../services/schedule.service'

const ViewTeacher = () => {
  // Local states
  const [isLoading, setIsLoading] = useState(false)
  const { id } = useParams()
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const teacher = userState.current_user

  const scheduleState = useSelector(state => state.schedule)
  const schedules = scheduleState.schedules
  // On created
  useEffect(() => {
    // Loading true
    setIsLoading(() => true)

    Promise.all([
      userServices.loadUser(dispatch, {id}),
      scheduleServices.loadAllSchedules(dispatch, {filter : {teacher : id}})
    ]).then(() => {
      // Loading false
      setIsLoading(() => false)
    }).catch(() => {
      // Loading false
      setIsLoading(() => false)
    })
  }, [id, dispatch])

  return(
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tutor Details</h4>
            </div>
            <div className="card-content">
            { (!isLoading && teacher) ? (
              <div className="card-body">
                <p><b>Name</b> - {teacher.name}</p>
                <p><b>Email</b> - {teacher.email}</p>
                <p><b>Country Code</b> - {teacher.country_code}</p>
                <p><b>Timezone</b> - {teacher.timezone}</p>
                <p><b>Mobile</b> - {teacher.mobile}</p>
                <div>
                  <b>Grade Subjects</b> -
                  <ol>
                    { 
                      teacher.grade_subjects.map(lgs => { 
                        return <li key={lgs._id}>{lgs.grade.name} - {lgs.subject.name}</li>
                      }) 
                    }
                  </ol>
                </div>
                <p><b>Address</b> - {teacher.address}</p>
                <p><b>Remarks</b> - {teacher.remarks}</p>

                { teacher.files && teacher.files.length ? (
                <h3 className="text-center">Files</h3> ) : '' }
                
                <div className="row">
                  { teacher.files.map((file, file_index) => {
                    return (
                    <div className="col-4" key={`FILE_${file_index}`}>
                      <ViewFile url={file} />
                    </div>
                  ) }) }
                </div>

                <h3 className="text-center">Time-Table (Student's timezone is color coded)</h3>
                <ViewUserSchedules type="T" schedules={schedules} />

                <h3 className="text-center">Availability</h3>
                <SetTutorAvailabilityTable availability={teacher.availability} view={true} />

              </div>
            ) : ( <Loading /> ) }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ViewTeacher