// Regarding API
const axios = require("axios");
const { baseURL } = require("../assets/js/config");
const $http = axios.create({
  baseURL,
});
const { userToken } = require("../utils");

//Parent
const VerifyParent = (dispatch, { password }) => {
  return $http
    .post(
      `subscriber/verify`,
      { password },
      {
        headers: {
          "x-auth-token": userToken,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw Error(err.response.data);
    });
};

const parentServices = {
  VerifyParent,
};

export default parentServices;
