// import PageHeading from '../../components/PageHeading'
import SetUser from '../../components/Teacher/SetUser'
import { useParams } from 'react-router-dom'
const StudentEdit = () => {
  const {id} = useParams()
  return (
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit student</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <SetUser type="S" id={id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default StudentEdit