// This is the list of all teachers
import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { ListGroup } from 'react-bootstrap'
// import {useToasts} from 'react-toast-notifications'
import { userType } from '../../utils'
import {formatDateFromNow} from '../../assets/js/common'

// Services
import chatServices from '../../services/chat.service'

const Teacher = () => {

  // States
  const dispatch = useDispatch()
  // const {addToast} = useToasts()

  // Chat state
  const chatState = useSelector(state => state.chat)
  const incoming_chats = chatState.incoming_chats

  // Created
  useEffect(() => {
    if(userType === 'A'){
      chatServices.loadIncomingChats(dispatch, {
        filter : {
          sort : -1
        }
      })
    }
  }, [dispatch])

  const probableUsersOfMsg = (users) => {
    let user_names = ''
    if(users){
      user_names = users.map(user => user.name).join(", ")
    }
    return user_names
  }

  return (
    <section className="section">
      <div className="col-12">
        <div className="card">
          <div className="card-body px-3 py-4-5">
            <h4 className="font-extrabold mb-4">Incoming Messages</h4>
            <ListGroup> 
            { incoming_chats.map(msg => {
              return (
                <ListGroup.Item key={msg._id}>
                  <div className="row">
                    <div className="col-md-7 col-sm-12">
                      <h6 className="mb-0 font-extrabold">{msg.sms_from} {`(${msg.fromCity}, ${msg.fromState}, ${msg.fromCountry})`}</h6>
                      <h6 className="mb-0 font-extrabold" style={{ color : '#01a106'}}>( {probableUsersOfMsg(msg.user)} )</h6>
                      
                    </div>
                    <div className="col-md-5 col-sm-12">
                      <small style={{float : 'right'}}>{formatDateFromNow(msg.created_at)}</small>
                    </div>
                  </div>
                  <p className="mb-0">{msg.sms_text}</p>
                  
                </ListGroup.Item>
              )
            })}
            </ListGroup>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Teacher