import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import {Spinner} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import materialServices from '../../services/material.service'
import ViewFile from '../../components/General/ViewFile'

const ViewMaterial = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const materialState = useSelector(state => state.material)
  const material = materialState.current_material

  // On created
  useEffect(() => {
    materialServices.loadMaterial(dispatch, {id})
  }, [id, dispatch])

  return(
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
        { material ? (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Material Details</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <p><b>Title</b> - {material.title}</p>
                <p><b>Remarks</b> - {material.remarks}</p>
                <p><b>By</b> - {material.from.name}</p>
                <p><b>To</b> - {material.to.name}</p>
                <h3 className="text-center">Files</h3>
                <div className="row">
                  { material.files.map((file, file_i) => {
                    return (
                    <div className="col-12 col-lg-4 col-md-6" key={`FILE_${file_i}`}>
                      <ViewFile url={file} />
                    </div>
                  ) }) }
                </div>
              </div>
            </div>
          </div>
         ) : <Spinner className="btn-center" animation="border" /> }
        </div>
      </div>
    </section>
  )
}

export default ViewMaterial