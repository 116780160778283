const path = require('path')
const ViewFile = ({url, show_remove, onRemove}) => {
	const viewThis = () => {
		window.open(url)
	}
	return (
		<div className="view-file flex">
			{ show_remove ? <span onClick={() => onRemove()} className="hover-allow" style={{float: 'right'}}>X</span> : '' }
    		<button onClick={viewThis} className="mb-2 btn btn-primary btn-center" type="button">View File</button>
    		<span style={{'wordBreak': 'break-all'}}>{path.basename(url)}</span>
    	</div>
	)
}

export default ViewFile