import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ViewFile from '../../components/General/ViewFile'
// import scheduleServices from '../../services/schedule.service'
import userServices from '../../services/user.service'

const ViewStudent = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const student = userState.current_user

  // const scheduleState = useSelector(state => state.schedule)
  // const schedules = scheduleState.schedules
  // On created
  useEffect(() => {
    userServices.loadUser(dispatch, {id})
    // scheduleServices.loadAllSchedules(dispatch, {filter : {student : id}})
  }, [id, dispatch])

  return(
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
        { student ? (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Student Details</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <p><b>Name</b> - {student.name}</p>
                <p><b>Email</b> - {student.email}</p>
                <p><b>Country Code</b> - {student.country_code}</p>
                <p><b>Timezone</b> - {student.timezone}</p>
                <p><b>Mobile</b> - {student.mobile}</p>
                { (student.grade_subjects && student.grade_subjects.length) ? (
                <div>
                  <b>Grade Subjects</b> -
                  <ol>
                    { 
                      student.grade_subjects.map(lgs => { 
                        return <li key={lgs._id}>{lgs.grade.name} - {lgs.subject.name}</li>
                      }) 
                    }
                  </ol>
                </div>) : '' }
                <p><b>Address</b> - {student.address}</p>
                <p><b>Remarks</b> - {student.remarks}</p>

                { student.files && student.files.length ? (
                <h3 className="text-center">Files</h3> ) : '' }
                <div className="row">
                  { student.files.map((file, file_index) => {
                    return (
                    <div key={`FILE_${file_index}`} className="col-4">
                      <ViewFile url={file} />
                    </div>
                  ) }) }
                </div>

              </div>
            </div>
          </div>
         ) : '' }
        </div>
      </div>
    </section>
  )
}

export default ViewStudent