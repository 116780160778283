// This is the list of all teachers
import {Modal} from 'react-bootstrap'
import {useState, useEffect} from 'react'
// import {Link} from 'react-router-dom'
import { useToasts } from 'react-toast-notifications';
import PageHeading from '../../components/PageHeading'
import SetGrade from '../../components/Grade/SetGrade'
import Confirm from '../../components/General/Confirm'

import { useSelector, useDispatch } from "react-redux"
import gradeServices from '../../services/grade.service'

const Grade = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  // On created
  useEffect(() => {
    gradeServices.loadGrades(dispatch)
  }, [dispatch])

  // Grade related
  const gradeState = useSelector(state => state.grade)
  const grades = gradeState.grades
  const [current_grade, setCurrentGrade] = useState(null)
  // const [isLoading, setLoading] = useState(false)
  // Set Grade Modal
  const [modal_state, setModalState] = useState(false)
  const handleModalClose = () => setModalState(false)
  const handleModalShow = () => setModalState(true)
  const [delete_modal, setDeleteModal] = useState(false)

  const showNewGrade = () => {
    setCurrentGrade(null)
    handleModalShow()
  }

  const showEditGrade = (grade) => {
    setCurrentGrade(grade)
    handleModalShow()
  }

  const deleteThisGradeConfirm = (id) => {
    setCurrentGrade(id)
    setDeleteModal(true)
  }

  const deleteThisGrade = () => {
    gradeServices.deleteGrade(dispatch, {id : current_grade}).then(() => {
      addToast('Grade deleted successfully', {appearance : 'success'})
      setCurrentGrade(null)
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
      setCurrentGrade(null)
    })
  }
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                      <button onClick={showNewGrade} type="button" className="btn btn-primary">Create</button>
                    </div>
                  </div>

                  <Modal centered show={modal_state} >
                    <Modal.Header>
                      <Modal.Title>Set Grade</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetGrade grade_id={current_grade} onHide={() => handleModalClose()} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-light" onClick={handleModalClose}>Close</button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete this grade ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={() => deleteThisGrade()} />
                  
                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>REMARKS</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        grades.map(grade => {
              
                          return <tr key={grade._id}>
                            <td className="text-bold-500">{grade.name}</td>
                            <td className="text-bold-500">{grade.remarks}</td>
                            <td className="text-bold-500">
                              <div className="buttons">
                                {/*<Link to={`teacher/${grade._id}`}><button className="btn btn-primary">View</button></Link>*/}
                                <button onClick={() => showEditGrade(grade._id)} className="btn btn-warning">Edit</button>
                                <button onClick={() => deleteThisGradeConfirm(grade._id)} className="btn btn-danger">Delete</button>
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>          
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default Grade