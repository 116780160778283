/* 
This is the list of all subjects
*/
import {Modal} from 'react-bootstrap'
import subjectServices from '../../services/subject.service'
import {useDispatch, useSelector} from 'react-redux'
import {useState, useEffect} from 'react'
import { useToasts } from 'react-toast-notifications';
// import {Link} from 'react-router-dom'
import PageHeading from '../../components/PageHeading'
import SetSubject from '../../components/Subject/SetSubject'
import Confirm from '../../components/General/Confirm'

const Subject = () => {
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const subjectState = useSelector(state => state.subject)
  const subjects = subjectState.subjects
  const [current_subject, setCurrentSubject] = useState(null)

  // On component create
  useEffect(() => {
    // Loading list of subjects
    subjectServices.loadSubjects(dispatch)
  }, [dispatch])


  // Set Subject related
  const [modal_state, setModalState] = useState(false)
  const handleModalClose = () => setModalState(false)
  const handleModalShow = () => setModalState(true)
  const [delete_modal, setDeleteModal] = useState(false)

  const showNewSubject = () => {
    setCurrentSubject(null)
    handleModalShow()
  }

  const showEditSubject = (id) => {
    setCurrentSubject(id)
    handleModalShow()
  }

  const deleteThisSubjectConfirm = (id) => {
    setCurrentSubject(id)
    setDeleteModal(true)
  }

  const deleteThisSubject = () => {
    subjectServices.deleteSubject(dispatch, {id : current_subject}).then(() => {
      addToast('Subject deleted successfully', {appearance : 'success'})
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                      <button onClick={showNewSubject} type="button" className="btn btn-primary">Create</button>
                    </div>
                  </div>

                  <Modal centered show={modal_state} onHide={handleModalClose}>
                    <Modal.Header>
                      <Modal.Title>Set Subject</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetSubject subject_id={current_subject} onHide={() => handleModalClose()} />
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-light" onClick={handleModalClose}>Close</button>
                    </Modal.Footer>
                  </Modal>

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete this subject ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={() => deleteThisSubject()} />
                  

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>REMARKS</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        subjects.map(subject => {
              
                          return <tr key={subject._id}>
                            <td className="text-bold-500">{subject.name}</td>
                            <td className="text-bold-500">{subject.remarks}</td>
                            <td className="text-bold-500">
                              <div className="buttons">
                                {/*<Link to={`teacher/${subject._id}`}><button className="btn btn-primary">View</button></Link>*/}
                                <button onClick={() => showEditSubject(subject._id)} className="btn btn-warning">Edit</button>
                                <button onClick={() => deleteThisSubjectConfirm(subject._id)} className="btn btn-danger">Delete</button>
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Subject