// This is the Dashboard Home

import { Button, ListGroup } from "react-bootstrap";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
// import PageHeading from '../../components/PageHeading'
import { userType } from "../../utils";
import { formatDate, formatDateFromNow } from "../../assets/js/common";

// Services
import attendanceServices from "../../services/attendance.service";
import reminderServices from "../../services/reminder.service";
import chatServices from "../../services/chat.service";

// Logos
import ZoomLogo from "../../assets/images/icons/zoom-logo.png";
import WebexLogo from "../../assets/images/icons/webex-logo.png";
import GoogleMeetLogo from "../../assets/images/icons/google-meet-logo.png";

// Components
import SummaryCard from "../../components/Home/summary-card";

const Home = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  // Attendance State
  const attendanceState = useSelector((state) => state.attendance);
  const attendances = attendanceState.attendances.slice(0, 4); // Showing only first 4 records

  // Reminder State
  const reminderState = useSelector((state) => state.reminder);
  const reminders = reminderState.reminders;

  // Chat state
  const chatState = useSelector((state) => state.chat);
  const chats = chatState.chats;
  const incoming_chats = chatState.incoming_chats;

  // Created
  useEffect(() => {
    const servicesList = [
      attendanceServices.loadAttendances(dispatch, {}),
      reminderServices.loadReminders(dispatch),
      chatServices.loadChats(dispatch, {
        filter: {
          received: true,
          limit: 5,
          sort: -1,
        },
      }),
    ];

    if (userType === "A") {
      servicesList.push(
        chatServices.loadIncomingChats(dispatch, {
          filter: {
            limit: 5,
            sort: -1,
          },
        })
      );
    }

    Promise.all(servicesList).catch((err) => {
      addToast(err.response.data, { appearance: "error" });
    });
  }, [dispatch, addToast]);

  const markThisAttendance = (id) => {
    attendanceServices
      .markAttendance(dispatch, { id })
      .then(() => {
        addToast("Attendance marked successfully", { appearance: "success" });
      })
      .catch((err) => {
        addToast(err.response.data, { appearance: "error" });
      });
  };

  const goToLink = (url) => {
    window.open(url);
  };

  const probableUsersOfMsg = (users) => {
    let user_names = "";
    if (users) {
      user_names = users.map((user) => user.name).join(", ");
    }
    return user_names;
  };

  const logoType = (platform) => {
    if (platform === "ZOOM") return ZoomLogo;
    else if (platform === "WEBEX") return WebexLogo;
    else if (platform === "GOOGLE") return GoogleMeetLogo;
  };
  return (
    <div className="page-content">
      <section>
        {userType === "A" ? (
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="row">
                <div className="col-6 col-lg-3 col-md-6">
                  <SummaryCard title="Profile Views" value="112.000" />
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                  <SummaryCard title="Teachers" value="4" />
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                  <SummaryCard title="Students" value="20" />
                </div>
                <div className="col-6 col-lg-3 col-md-6">
                  <SummaryCard title="Saved Post" value="112" />
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-12">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <h4 className="font-extrabold mb-4">Incoming Messages</h4>
                  <ListGroup>
                    {incoming_chats.map((msg) => {
                      return (
                        <ListGroup.Item key={msg._id}>
                          <h6 className="mb-0 font-extrabold">
                            {msg.sms_from}{" "}
                            {`(${msg.fromCity}, ${msg.fromState}, ${msg.fromCountry})`}
                          </h6>
                          <h6
                            className="mb-0 font-extrabold"
                            style={{ color: "#01a106" }}
                          >
                            ( {probableUsersOfMsg(msg.user)} )
                          </h6>
                          <p className="mb-0">{msg.sms_text}</p>
                          <small style={{ float: "right" }}>
                            {formatDateFromNow(msg.created_at)}
                          </small>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {userType === "S" ? (
          <div className="row">
            {attendances.map((event) => {
              return (
                <div className="col-6 col-lg-3 col-md-6" key={event._id}>
                  <div className="card">
                    <div className="card-body px-2 py-3-3">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h5 className="font-extrabold mb-2 text-center">
                            Attendance
                          </h5>
                          <h6 className="font-extrabold mb-1">
                            By {event.created_by.name}
                          </h6>
                          <h6 className="text-muted font-semibold mb-1">
                            {formatDate(event.created_at)}
                          </h6>
                          {event.marked_at ? (
                            <h6 className="text-success">Attendance Marked</h6>
                          ) : (
                            <Button
                              onClick={() => markThisAttendance(event._id)}
                              variant="success"
                            >
                              Mark Present
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}

        {userType === "S" || userType === "T" ? (
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <h4 className="font-extrabold mb-4">Latest Class Reminder</h4>
                  {reminders.map((reminder) => {
                    return (
                      <div key={reminder._id} className="row">
                        <div className="col-12">
                          <h6 className="font-extrabold mb-0">
                            Your class is scheduled on{" "}
                            {formatDate(reminder.class_time)}. Please join on
                            the following meeting link
                          </h6>
                          <img
                            alt="Reminder Logo"
                            style={{ cursor: "pointer" }}
                            onClick={() => goToLink(reminder.meeting_url)}
                            className="mt-2"
                            width="150"
                            src={logoType(reminder.meeting_platform)}
                          />
                          <small className="mt-2" style={{ float: "right" }}>
                            {formatDate(reminder.created_at)}
                          </small>
                          {/*<hr />*/}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-md-5 col-sm-12">
              <div className="card">
                <div className="card-body px-3 py-4-5">
                  <h4 className="font-extrabold mb-4">Recent Messages</h4>
                  <ListGroup>
                    {chats.map((msg) => {
                      return (
                        <ListGroup.Item key={msg._id}>
                          <h6 className="mb-0 font-extrabold">
                            {msg.from.name}
                          </h6>
                          <p className="mb-0">{msg.content}</p>
                          <small style={{ float: "right" }}>
                            {formatDateFromNow(msg.created_at)}
                          </small>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default Home;
