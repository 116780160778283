const initialState = {
  current_subscriber: null,
  subscribers: [],
};

const subscribersReducer = function (state = initialState, action) {
  let new_state;
  switch (action.type) {
    case "SET_SUBSCRIBERS":
      new_state = {
        ...state,
        subscribers: action.payload,
      };
      break;

    case "SET_SUBSCRIBER":
      new_state = {
        ...state,
        current_subscriber: action.payload,
      };
      break;

    default:
      new_state = state;
  }
  return new_state;
};

export default subscribersReducer;
