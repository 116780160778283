// This is the list of all teachers
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import PageHeading from "../../components/PageHeading";
import parentServices from "../../services/parent.service";
import subscriberServices from "../../services/subscriber.service";
import { useParams } from "react-router-dom";
import SetSubscriber from "../../components/Subscriber/SetSubscriber";

const Parent = () => {
  const dispatch = useDispatch();
  const [password_modal, setPasswordModal] = useState(true);
  const [subscriber_modal, setSubscriberModal] = useState(false);
  const handleModalClose = () => setSubscriberModal(false);
  const handleModalShow = () => setSubscriberModal(true);
  const [password, setPassword] = useState("");
  const current_subscriber = useSelector(
    (state) => state.subscriber.current_subscriber
  );
  const { addToast } = useToasts();

  const verifyPassword = () => {
    if (password !== "") {
      parentServices
        .VerifyParent(dispatch, { password })
        .then((res) => {
          if (res.data.success) {
            addToast(res.data.msg, { appearance: "success" });
            subscriberServices.loadSubscriber(dispatch, {
              id: res.data.data.stripe_subscription_id,
            });
            setPasswordModal(false);
          }
        })
        .catch((err) => {
          addToast(err.message, { appearance: "error" });
        });
    } else {
      addToast("Password should not be empty", { appearance: "error" });
    }
  };

  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4 className="card-title">Parent</h4>
                </div>
                <div className="card-body">
                  {/*Password Verification*/}
                  <Modal centered show={password_modal}>
                    <Modal.Header>
                      <Modal.Title>Parent Verification</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Form.Group>
                        <Form.Label>Enter Password</Form.Label>
                        <Form.Control
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                        />
                      </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                      <Row>
                        <Col>
                          <Button
                            className="btn-center"
                            onClick={verifyPassword}
                          >
                            Proceed
                          </Button>
                        </Col>
                        <Col>
                          <a href="/dashboard">
                            <Button className="btn-center">Cancel</Button>
                          </a>
                        </Col>
                      </Row>
                    </Modal.Footer>
                  </Modal>

                  {/* {Subscriber Edit Modal} */}
                  <Modal centered show={subscriber_modal}>
                    <Modal.Header>
                      <Modal.Title>Set Subscriber</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <SetSubscriber
                        current_subscriber={current_subscriber}
                        onHide={() => handleModalClose()}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-light"
                        onClick={handleModalClose}
                      >
                        Close
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {current_subscriber !== null && (
                    <div className="row">
                      <div className="col-6">
                        <h5>Current Subscription Details</h5>
                        <hr />
                        <div className="row">
                          <p className="col-5">No of sessions</p>
                          <p className="col-7">
                            : {current_subscriber.sessions}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-5">Grade</p>
                          <p className="col-7">: {current_subscriber.grade}</p>
                        </div>
                        <div className="row">
                          <p className="col-5">Subjects </p>
                          <p className="col-7">
                            : {current_subscriber.subjects.join(", ")}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-5">Kids </p>
                          <p className="col-7">
                            : {current_subscriber.children.join(", ")}
                          </p>
                        </div>
                        <Button className="btn-left" onClick={handleModalShow}>
                          Edit
                        </Button>
                      </div>
                      <div className="col-6">
                        <h5>Payment</h5>
                        <hr />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Parent;
