import { useState, useEffect } from "react";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import subscriberServices from "../../services/subscriber.service";

const ShowSubscriber = ({ id, onHide }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const subscriberState = useSelector((state) => state.subscriber);

  const current_subscriber = subscriberState.current_subscriber;
  const [isLoading, setLoading] = useState(false);

  //   Watching for new grade id
  useEffect(() => {
    if (id) {
      setLoading(true);
      subscriberServices
        .loadSubscriber(dispatch, { id })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          addToast(err.response.data, { appearance: "error" });
        });
    } else {
      dispatch({
        type: "SET_SUBSCRIBER",
        payload: null,
      });
    }
  }, [id, dispatch, addToast]);

  return (
    <div>
      {isLoading || current_subscriber === null ? (
        <Spinner className="btn-center" animation="border" />
      ) : (
        <div>
          {console.log("subscriber", current_subscriber)}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.name}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.email}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.mobile}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.start_date}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Company</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.company}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.country}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>State</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.state}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.city}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.address}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Number of Children</Form.Label>
                <Form.Control
                  defaultValue={current_subscriber.children.length}
                  type="text"
                />
              </Form.Group>
            </Col>
            
          </Row>

          <hr />
          <h5><center>Children Details</center></h5>
          {current_subscriber.children.map(child => {
            return <div key={child._id}>
            <p>Name - {child.name}</p>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Grade</Form.Label>
                  <Form.Control
                    defaultValue={child.grade.name}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Subjects</Form.Label>
                  <Form.Control
                    defaultValue={child.subjects.map(s => s.name).join(", ")}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Sessions</Form.Label>
                  <Form.Control
                    defaultValue={child.sessions}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          })}
          
        </div>
      )}
    </div>
  );
};

export default ShowSubscriber;
