// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})

const {userToken} = require('../utils')

//Loading schedule
function loadAllSchedules(dispatch, {filter}){
	let query_arr = []
	if(filter){
		for(let key in filter){
			query_arr.push(`${key}=${filter[key]}`)
		}
	}
	let query_str = query_arr.join('&')
	return $http.get(`schedule/all?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SCHEDULES',
      		payload : res.data
    	})
	})
}

//Loading schedule
function loadSchedule(dispatch, {filter}){
	let query_arr = []
	if(filter){
		for(let key in filter){
			query_arr.push(`${key}=${filter[key]}`)
		}
	}
	let query_str = query_arr.join('&')
	return $http.get(`schedule?${query_str}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SCHEDULE',
      		payload : res.data
    	})
	})
}

//Updating schedule
function updateSchedule(dispatch, {data}){
	return $http.put(`schedule`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_SCHEDULE',
      		payload : res.data
    	})
	})
}

const scheduleServices = {
	loadAllSchedules, loadSchedule, updateSchedule
}

export default scheduleServices