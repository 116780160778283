// This is the list of all teachers
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Modal, Button} from 'react-bootstrap'

import {useToasts} from 'react-toast-notifications'
import PageHeading from '../../components/PageHeading'
import ShowChats from '../../components/Chat/ShowChats'
import Confirm from '../../components/General/Confirm'
import userServices from '../../services/user.service'
import { userType } from '../../utils'

const Student = () => {
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const userState = useSelector(state => state.user)
  const students = userState.students

  const [toggle_chat_modal, setToggleChatModal] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  const [current_user, setCurrentUser] = useState(null)
  const [current_chat_user_id, setCurrentChatUser] = useState(null)

  // Created
  useEffect(() => {
    userServices.loadStudents(dispatch)
  }, [dispatch])
  
  const deleteThisStudentConfirm = (id) => {
    setCurrentUser(id)
    setDeleteModal(true)
  }

  const deleteThisStudent = () => {
    userServices.deleteUser(dispatch, {id : current_user}).then(() => {
      addToast('Student deleted successfully', {appearance : 'success'})
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }

  const showMessageModal = (user_id) => {
    setCurrentChatUser(() => user_id)
    setToggleChatModal(() => true)
  }

  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <h4 className="card-title">My Students</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 d-flex justify-content-end">
                    { (userType==='A') ? (
                      <Link to="/student/new"><button className="btn btn-primary">Create</button></Link>
                    ) : '' }
                    </div>
                  </div>

                  {/*Chat*/}
                  <Modal centered show={toggle_chat_modal}>
                    <Modal.Header>
                      <Modal.Title>Messages</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <ShowChats user_id={current_chat_user_id} />
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setToggleChatModal(false)} variant="danger">Close</Button>
                    </Modal.Footer>
                  </Modal> 

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete this tutor ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={() => deleteThisStudent()} />
                  

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          
                          { (userType==='A') ? ( 
                            <>
                            <th>COUNTRY CODE</th>
                            <th>MOBILE</th>
                            <th>STATUS</th>
                            </>
                           ) : null}
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        students.map(student => {                          
                          return <tr key={student._id}>
                            <td className="text-bold-500">{student.name}</td>
                            <td className="text-bold-500">{student.email}</td>
                            { (userType==='A') ? ( 
                              <>
                              <td className="text-bold-500">+{student.country_code}</td>
                              <td className="text-bold-500">{student.mobile}</td>
                              <td className="text-bold-500"><span className="material-icons" style={{ color: `${student.status ? 'green' : 'red'}`}}>{student.status ? 'done' : 'close'}</span></td>
                              </>
                            ) : null}
                            
                            {/*<td className="text-bold-500">{student.remarks}</td>*/}
                            <td className="text-bold-500">
                              <div className="buttons">
                                { (userType==='T') ? (
                                <div style={{float : 'left'}}>
                                  <Link to={`student/schedule/${student._id}`}><button className="btn btn-info">Class Schedule</button></Link>
                                  <Link to={`student/attendance/${student._id}`}><button className="btn btn-dark">Attendance</button></Link>
                                </div>
                                ) : '' }
                                { (userType==='A') ? (
                                  <div style={{float : 'left'}}>
                                    <Link to={`student/${student._id}`}><button className="btn btn-primary">View</button></Link>
                                    <Link to={`student/edit/${student._id}`}><button className="btn btn-warning">Edit</button></Link>
                                    <button onClick={() => deleteThisStudentConfirm(student._id)} className="btn btn-danger">Delete</button>
                                  </div>
                                  ) : (
                                    <button type="button" onClick={() => showMessageModal(student._id)} className="btn btn-info">Message</button>
                                  )
                                }
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Student