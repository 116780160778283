const initialState = {
	current_user : null,
	teachers : [],
	students : [],
}

const usersReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_TEACHERS':
		new_state = {
			...state,
			teachers : action.payload
		}
		break;

		case 'SET_STUDENTS':
		new_state = {
			...state,
			students : action.payload
		}
		break;

		case 'SET_USER':
		new_state = {
			...state,
			current_user : action.payload
		}
		break;

		case 'ADD_TEACHER':
		new_state = {
			...state,
			teachers : state.teachers.concat(action.payload)
		}
		break;

		case 'REMOVE_USER':
		new_state = {
			...state,
			teachers : [...state.teachers.filter(user => user._id !== action.payload._id)],
			students : [...state.students.filter(user => user._id !== action.payload._id)]
		}
		break;
		
		default:
		new_state = state
	}
	return new_state
}

export default usersReducer