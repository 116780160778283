// import PageHeading from '../../components/PageHeading'
import SetUser from '../../components/Teacher/SetUser'

const StudentNew = () => {
  return (
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Create a new student</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <SetUser type="S" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StudentNew