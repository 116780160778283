const initialState = {
	chats : [],
	incoming_chats : [],
	recent_chats : []
}

const chatsReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_CHATS':
		new_state = {
			...state,
			chats : action.payload
		}
		break;

		case 'SET_INCOMING_CHATS':
		new_state = {
			...state,
			incoming_chats : action.payload
		}
		break;

		case 'ADD_CHAT':
		new_state = {
			...state,
			chats : [...state.chats, action.payload]
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default chatsReducer