import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userType } from "../utils";
import LogoImage from "../assets/images/logo/logo.png";
import CrossIcon from "../assets/images/icons/cross.png";
const SideBar = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state) => state.sidebar);
  const toggleSidebar = () => {
    dispatch({
      type: "TOGGLE_SIDEBAR",
    });
  };

  let common_routes = [
    {
      name: "Dashboard",
      link: "/dashboard",
      styles: { bg_color: "#00ffff", color: "#069abb" },
    },
  ];

  let admin_routes = [
    {
      name: "Incoming Msgs",
      link: "/chat/incoming",
      styles: { bg_color: "rgb(90 254 87)", color: "rgb(7 163 12)" },
    },
    {
      name: "Tutors",
      link: "/teacher",
      styles: { bg_color: "#fe5957", color: "#a3383d" },
    },
    {
      name: "Students",
      link: "/student",
      styles: { bg_color: "#fdfe58", color: "#6c6f20" },
    },
    {
      name: "Grades",
      link: "/grade",
      styles: { bg_color: "#57dd5a", color: "#167025" },
    },
    {
      name: "Subjects",
      link: "/subject",
      styles: { bg_color: "#ffa214", color: "#996101" },
    },
    {
      name: "Grade Subjects",
      link: "/gradesubject",
      styles: { bg_color: "#c073e3", color: "#713da1" },
    },
    {
      name: "Attendance Log",
      link: "/attendance",
      styles: { bg_color: "#ff45ff", color: "#8b1db1" },
    },
    {
      name: "Coupon",
      link: "/coupon",
      styles: { bg_color: "#00ffff", color: "#069abb" },
    },
    {
      name: "Subscriber",
      link: "/subscriber",
      styles: { bg_color: "rgb(90 254 87)", color: "rgb(7 163 12)" },
    },
    // {
    //   name : 'Tutor Availability', link : '/tutoravailability'
    // }
  ];

  let teacher_routes = [
    {
      name: "My Students",
      link: "/student",
      styles: { bg_color: "#fdfe58", color: "#6c6f20" },
    },
    {
      name: "Shared Materials",
      link: "/material",
      styles: { bg_color: "#57dd5a", color: "#167025" },
    },
    {
      name: "Grade Subjects",
      link: "/gradesubject",
      styles: { bg_color: "#c073e3", color: "#713da1" },
    },
  ];

  let student_routes = [
    {
      name: "My Tutors",
      link: "/teacher",
      styles: { bg_color: "#fe5957", color: "#a3383d" },
    },
    {
      name: "Shared Materials",
      link: "/material",
      styles: { bg_color: "#57dd5a", color: "#167025" },
    },
    {
      name: "Attendance Log",
      link: "/attendance",
      styles: { bg_color: "#ff45ff", color: "#8b1db1" },
    },
    // {
    //   name: "Parent",
    //   link: "/parent",
    //   styles: { bg_color: "#fe5957", color: "#a3383d" },
    // },
  ];

  let routes = {
    S: student_routes,
    T: teacher_routes,
    A: admin_routes,
  };
  return (
    <div id="sidebar" className="active">
      {sidebarState.is_active ? (
        <div className="sidebar-wrapper active">
          <div className="sidebar-header">
            <div className="d-flex justify-content-between">
              <div className="logo">
                <img
                  alt="Toggle Sidebar"
                  onClick={() => toggleSidebar()}
                  className="mb-3 sidebar-mobile-remove hover-allow"
                  style={{ float: "right", width: "25px" }}
                  height="25"
                  src={CrossIcon}
                />
                <Link to="/dashboard">
                  <img src={LogoImage} alt="Logo" />
                </Link>
              </div>
              {/*<div className="toggler">
                  <a href="#" className="sidebar-hide d-xl-none d-block"><i className="bi bi-x bi-middle" /></a>
                </div>*/}
            </div>
          </div>
          <div className="sidebar-menu">
            {userType ? (
              <ul className="menu">
                {[...common_routes, ...routes[userType]].map(
                  (route_obj, route_key) => {
                    return (
                      <li className="sidebar-item" key={`A_ROUTE_${route_key}`}>
                        <Link
                          to={route_obj.link}
                          className="sidebar-link"
                          style={{
                            backgroundColor: route_obj.styles.bg_color,
                            color: route_obj.styles.color,
                          }}
                        >
                          <span>{route_obj.name}</span>
                        </Link>
                      </li>
                    );
                  }
                )}
                {/*<li className="sidebar-item  has-sub">
                  <a href="#" className="sidebar-link">
                    <i className="bi bi-x-octagon-fill" />
                    <span>Errors</span>
                  </a>
                  <ul className="submenu ">
                    <li className="submenu-item ">
                      <a href="error-403.html">403</a>
                    </li>
                    <li className="submenu-item ">
                      <a href="error-404.html">404</a>
                    </li>
                    <li className="submenu-item ">
                      <a href="error-500.html">500</a>
                    </li>
                  </ul>
                </li>*/}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default SideBar;
