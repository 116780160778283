const initialState = {
	current_grade_subject : null,
	grade_subjects : [],
}

const gradeSubjectsReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_GRADE_SUBJECTS':
		new_state = {
			...state,
			grade_subjects : action.payload
		}
		break;

		case 'SET_GRADE_SUBJECT':
		new_state = {
			...state,
			current_grade_subject : action.payload
		}
		break;

		case 'ADD_GRADE_SUBJECT':
		new_state = {
			...state,
			grade_subjects : state.grade_subjects.concat(action.payload)
		}
		break; 

		case 'REMOVE_GRADE_SUBJECT':
		new_state = {
			...state,
			grade_subjects : [...state.grade_subjects.filter(gs => action.payload._id !== gs._id)]
		}
		break; 

		default:
		new_state = state
	}
	return new_state
}

export default gradeSubjectsReducer