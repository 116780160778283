const initialState = {
	current_tutor_availability : null,
	tutor_availabilities : [],
}

const tutorAvailabilitiesReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'SET_TUTOR_AVAILABILITIES':
		new_state = {
			...state,
			tutor_availabilities : action.payload
		}
		break;

		case 'SET_TUTOR_AVAILABILITY':
		new_state = {
			...state,
			current_tutor_availability : action.payload
		}
		break;

		case 'ADD_TUTOR_AVAILABILITY':
		new_state = {
			...state,
			tutor_availabilities : state.tutor_availabilities.concat(action.payload)
		}
		break;

		case 'REMOVE_TUTOR_AVAILABILITY':
		new_state = {
			...state,
			tutor_availabilities : [...state.tutor_availabilities.filter(availability => availability._id !== action.payload._id)]
		}
		break;
		
		default:
		new_state = state
	}
	return new_state
}

export default tutorAvailabilitiesReducer