// React router
import { Switch, Route } from "react-router-dom";
// Toast
import { ToastProvider } from "react-toast-notifications";
// Pages
import ErrorComponent from "./pages/Error";
import Login from "./pages/Login";
import Home from "./pages/Home";

import Teacher from "./pages/Teacher";
import TeacherNew from "./pages/Teacher/new";
import TeacherCalendar from "./pages/Teacher/calendar";
import TeacherEdit from "./pages/Teacher/edit";
import ViewTeacher from "./pages/Teacher/view";

import Parent from "./pages/Parent";

import Student from "./pages/Student";
import StudentNew from "./pages/Student/new";
import ViewStudent from "./pages/Student/view";
import StudentEdit from "./pages/Student/edit";
import StudentSchedule from "./pages/Student/schedule";
import StudentAttendance from "./pages/Student/attendance";

import Attendance from "./pages/Attendance";
import Material from "./pages/Material";
import ViewMaterial from "./pages/Material/view";

import Subject from "./pages/Subject";
import Grade from "./pages/Grade";
import Coupon from "./pages/Coupon";
import Subscriber from "./pages/Subscriber";
import GradeSubject from "./pages/GradeSubject";
import ViewGradeSubject from "./pages/GradeSubject/view";

import IncomingMessage from "./pages/Chat/incoming";

import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

function App() {
  return (
    <div className="App">
      <ToastProvider
        placement="top-center"
        autoDismiss
        autoDismissTimeout={2000}
      >
        <Switch>
          <PublicRoute
            restricted={true}
            path="/login"
            component={Login}
            exact
          />
          <PrivateRoute path="/dashboard" component={Home} exact />

          <PrivateRoute path="/teacher" component={Teacher} exact />
          <PrivateRoute path="/teacher/new" component={TeacherNew} exact />
          <PrivateRoute
            path="/teacher/calendar"
            component={TeacherCalendar}
            exact
          />
          <PrivateRoute path="/teacher/:id" component={ViewTeacher} exact />
          <PrivateRoute
            path="/teacher/edit/:id"
            component={TeacherEdit}
            exact
          />

          <PrivateRoute path="/parent" component={Parent} exact />

          <PrivateRoute path="/student" component={Student} exact />
          <PrivateRoute path="/student/new" component={StudentNew} exact />
          <PrivateRoute path="/student/:id" component={ViewStudent} exact />
          <PrivateRoute
            path="/student/edit/:id"
            component={StudentEdit}
            exact
          />
          <PrivateRoute
            path="/student/schedule/:id"
            component={StudentSchedule}
            exact
          />
          <PrivateRoute
            path="/student/attendance/:id"
            component={StudentAttendance}
            exact
          />

          <PrivateRoute
            path="/chat/incoming"
            component={IncomingMessage}
            exact
          />
          <PrivateRoute path="/attendance" component={Attendance} exact />
          <PrivateRoute path="/material" component={Material} exact />
          <PrivateRoute path="/material/:id" component={ViewMaterial} exact />

          <PrivateRoute path="/subject" component={Subject} exact />

          <PrivateRoute path="/grade" component={Grade} exact />
          <PrivateRoute path="/coupon" component={Coupon} exact />
          <PrivateRoute path="/subscriber" component={Subscriber} exact />

          <PrivateRoute path="/gradesubject" component={GradeSubject} exact />
          <PrivateRoute
            path="/gradesubject/:id"
            component={ViewGradeSubject}
            exact
          />
          <Route component={ErrorComponent} />
        </Switch>
      </ToastProvider>
    </div>
  );
}

export default App;
