import { useState, useEffect } from "react";
import { Form, Button, Spinner, Row, Col } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import couponServices from "../../services/coupon.service";

const SetCoupon = ({ coupon_id, onHide }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const couponState = useSelector((state) => state.coupon);

  const current_coupon = couponState.current_coupon;
  const [isLoading, setLoading] = useState(false);
  const [coupon_code, setCouponCode] = useState("");
  const [coupon_type, setCouponType] = useState("A");
  const [coupon_amount, setCouponAmount] = useState(null);
  const [coupon_percentage, setCouponPercentage] = useState("");
  const [coupon_maxusage, setCouponMaxusage] = useState("");
  const [coupon_remarks, setCouponRemarks] = useState("");

  const proceed = () => {
    let coupon_data = {
      code: coupon_code,
      type: coupon_type,
      amount: coupon_amount,
      percentage: coupon_percentage,
      max_usage: coupon_maxusage,
      remarks: coupon_remarks,
    };
    if (coupon_id) {
      // If update
      couponServices
        .updateCoupon(dispatch, { id: coupon_id, data: coupon_data })
        .then(() => {
          addToast("Coupon updated successfully", { appearance: "success" });
          if (onHide) {
            onHide();
          }
        })
        .catch((err) => {
          addToast(err.response.data, { appearance: "error" });
        });
    } else {
      // If entry
      couponServices
        .createCoupon(dispatch, { data: coupon_data })
        .then(() => {
          addToast("Coupon created successfully", { appearance: "success" });
          if (onHide) {
            onHide();
          }
        })
        .catch((err) => {
          addToast(err.response.data, { appearance: "error" });
        });
    }
  };

  //   Watching for new grade id
  useEffect(() => {
    // console.log("id", coupon_id);
    if (coupon_id) {
      setLoading(true);
      couponServices
        .loadCoupon(dispatch, { id: coupon_id })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          addToast(err.response.data, { appearance: "error" });
        });
    } else {
      couponServices.emptyCoupon(dispatch);
    }
  }, [coupon_id, dispatch, addToast]);

  // Watching for current loaded coupon
  useEffect(() => {
    if (current_coupon && coupon_id) {
      setCouponType(current_coupon.type);
      setCouponCode(current_coupon.code);
      setCouponPercentage(current_coupon.percentage);
      setCouponMaxusage(current_coupon.max_usage);
      setCouponAmount(current_coupon.amount);
      setCouponRemarks(current_coupon.remarks);
    }
  }, [current_coupon]);

  return (
    <div>
      {isLoading ? (
        <Spinner className="btn-center" animation="border" />
      ) : (
        <div>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  value={coupon_code}
                  onChange={(e) => setCouponCode(e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>Coupon Type</Form.Label>
              <Form.Control
                as="select"
                value={coupon_type}
                onChange={(e) => setCouponType(e.target.value)}
              >
                <option>Select Type</option>
                <option value="A">Amount</option>
                <option value="P">Percentage</option>
              </Form.Control>
            </Col>
          </Row>

          <Form.Group>
            {coupon_type === "A" ? (
              <>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  value={coupon_amount}
                  onChange={(e) => setCouponAmount(e.target.value)}
                  type="number"
                />
              </>
            ) : (
              <>
                <Form.Label>Percentage</Form.Label>
                <Form.Control
                  value={coupon_percentage}
                  onChange={(e) => setCouponPercentage(e.target.value)}
                  type="number"
                />
              </>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>Max Usage</Form.Label>
            <Form.Control
              value={coupon_maxusage}
              onChange={(e) => setCouponMaxusage(e.target.value)}
              type="text"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              value={coupon_remarks}
              onChange={(e) => setCouponRemarks(e.target.value)}
              rows="3"
              as="textarea"
            />
          </Form.Group>

          <Button className="btn-center" onClick={proceed}>
            {coupon_id ? "Update Coupon" : "Save Coupon"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SetCoupon;
