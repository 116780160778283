import {useState, useEffect} from 'react'
import 'rc-slider/assets/index.css';
const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const SetTutorAvailabilityTable = ({availability, view, onchange}) => {

	let slots = ['4:00 a.m. - 8:30 a.m.', '5:00 p.m. - 11:30 p.m.']
	let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	// Defining current states for time_table
	let initial_availability_table = availability ? availability : {}
	const [current_availability, setAvailability] = useState(initial_availability_table)

	function updateAvailability(cell_key, val){
		
		// Updating the timetable object if not view type
		if(!view){
			let new_availability = {...current_availability}
			if(val && (val.length == 2) && (val[0] != val[1])){
				// If cell key not present create it
				new_availability[cell_key] = val
			}else{
				delete new_availability[cell_key]
			}

			setAvailability(new_availability)
			onchange(new_availability)
		}
	}

	const sliderLabelFormatter = (value, slot_index) => {
		const meridian = slot_index ? 'p.m.' : 'a.m.'

		const time = `${Math.trunc(value)}:${(value % 1 !== 0) ? '30' : '00'}`
		return `${time} ${meridian}`
	}

	useEffect(() => {
		if(availability){
			setAvailability(availability)
		}
	}, [availability])

	return (
		<div className="table-responsive">
			<table className="table table-lg table-bordered no-padding all-text-center">
				<tbody>
					<tr>
						<th></th>
						{
							slots.map((slot, slot_i) => {
								return <td key={`HEAD_COL_${slot_i}`}>{slot}</td>
							})
						}
					</tr>
					{
						days.map((day, day_i) => {
							return <tr key={`DAY_${day_i}`}><td>{day}</td>
								{	
									slots.map((slot, slot_i) => {
										return (
											<td key={`HEAD_COL_${day_i}_${slot_i}`}>
												<div className="px-3">
    												<Range 
    													min={(slot_i == 0) ? 4 : 5} 
    													max={(slot_i == 0) ? 8.5 : 11.5} 
    													step={0.5}
    													dots={true}
    													tipFormatter={(value) => sliderLabelFormatter(value, slot_i)}
    													value={current_availability ? current_availability[`${day_i}_${slot_i}`] : null}
    													onChange = {(val) => updateAvailability(`${day_i}_${slot_i}`, val)}  
    												/>
												</div>
											</td>
										)
									})
								}
							</tr>
						})
					}
				</tbody>
			</table>
		</div>
	)
}

export default SetTutorAvailabilityTable