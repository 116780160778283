// This is the list of all teachers
import {Link} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Modal, Button, Form} from 'react-bootstrap'
import {useToasts} from 'react-toast-notifications'
import PageHeading from '../../components/PageHeading'
import ShowChats from '../../components/Chat/ShowChats'
import Confirm from '../../components/General/Confirm'
import userServices from '../../services/user.service'
import { userType } from '../../utils'
// Slider
const Slider = require('rc-slider');
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Teacher = () => {

  // Constants
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // States
  const dispatch = useDispatch()
  const {addToast} = useToasts()
  const userState = useSelector(state => state.user)
  const teachers = userState.teachers

  // Local States
  const [toggle_chat_modal, setToggleChatModal] = useState(false)
  const [delete_modal, setDeleteModal] = useState(false)
  const [current_user, setCurrentUser] = useState(null)
  const [current_chat_user_id, setCurrentChatUser] = useState(null)
  const [filter, setFilter] = useState({ day : undefined, slot : undefined, time : undefined, status : undefined })

  // Created
  useEffect(() => {
    userServices.loadTeachers(dispatch)
  }, [dispatch])
  
  const deleteThisTeacherConfirm = (id) => {
    setCurrentUser(id)
    setDeleteModal(true)
  }

  const deleteThisTeacher = () => {
    userServices.deleteUser(dispatch, {id : current_user}).then(() => {
      addToast('Teacher deleted successfully', {appearance : 'success'})
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }

  const showMessageModal = (user_id) => {
    setCurrentChatUser(() => user_id)
    setToggleChatModal(() => true)
  }

  const updateFilter = (key, val) => {
    let local_filter = Object.assign({}, filter)
    local_filter[key] = val

    // Setting the state
    setFilter(local_filter)

    // Load filtered tutors
    userServices.loadTeachers(dispatch, local_filter)
  }
  return (
    <div>
      <PageHeading title="" />
      <section className="section">
        <div className="row" id="basic-table">
          <div className="col-12">
            <div className="card">
              <div className="card-content">
                <div className="card-header">
                  <div className="row">
                    <div className="col-6">
                      <h4 className="card-title">My Tutors</h4>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                    { (userType==='A') ? (
                      <div class="buttons">
                        <Link to="/teacher/new"><button className="btn btn-primary">Create</button></Link>
                        <Link to="/teacher/calendar"><button className="btn btn-info">Calendar</button></Link>
                      </div>
                    ) : '' }
                    </div>
                  </div>
                  
                </div>
                <div className="card-body">
                  { (userType==='A') ? ( 
                  <div className="row">

                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="email-id-column">Filter By Status</label>
                        <Form.Control as="select" value={filter.status} onChange={(e) => updateFilter('status', e.target.value)} >
                          <option value="">All</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Form.Control>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="email-id-column">Filter By Day Available</label>
                        <Form.Control as="select" value={filter.day} onChange={(e) => updateFilter('day', e.target.value)}>
                          <option value=''>Select</option>
                          {days.map((day, day_i) => {
                            return (<option value={day_i} key={`DAY_${day_i}`}>{day}</option>)
                          })}
                        </Form.Control>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="email-id-column">Filter By Slot Available</label>
                        <Form.Control as="select" value={filter.slot} onChange={(e) => updateFilter('slot', e.target.value)} >
                          <option value="">Select</option>
                          <option value={0}>Slot 1 (Morning)</option>
                          <option value={1}>Slot 2 (Evening)</option>
                        </Form.Control>
                      </div>
                    </div>

                    { filter.slot ? 
                      (<div className="col-3">
                        <div className="form-group">
                          <label htmlFor="email-id-column">Filter By Time Available</label>
                          <Range 
                            min={(filter.slot == 0) ? 4 : 5} 
                            max={(filter.slot == 0) ? 8.5 : 11.5} 
                            step={0.5}
                            dots={true}
                            onChange = {(val) => updateFilter(`time`, val)}  
                          />
                        </div>
                      </div>)
                      : null }
          
                  </div>
                  ) : null }

                  {/*Delete*/}                
                  <Confirm title="Confirm Delete" message="Are you sure want to delete this tutor ?" modal_state={delete_modal} onClose={(val) => setDeleteModal(false)} onSuccess={() => deleteThisTeacher()} />
                  
                  {/*Chat*/}
                  <Modal centered show={toggle_chat_modal}>
                    <Modal.Header>
                      <Modal.Title>Chat</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <ShowChats user_id={current_chat_user_id} />
                    </Modal.Body>

                    <Modal.Footer>
                      <Button onClick={() => setToggleChatModal(false)} variant="danger">Close</Button>
                    </Modal.Footer>
                  </Modal> 

                  {/* Table with outer spacing */}
                  <div className="table-responsive">
                    <table className="table table-lg">
                      <thead>
                        <tr>
                          <th>NAME</th>
                          <th>EMAIL</th>
                          
                          { (userType==='A') ? ( 
                            <>
                            <th>REMARKS</th>
                            <th>COUNTRY CODE</th>
                            <th>MOBILE</th>
                            </>
                           ) : null}
                          <th>ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        teachers.map(teacher => {                          
                          return <tr key={teacher._id}>
                            <td className="text-bold-500">{teacher.name}</td>
                            <td className="text-bold-500">{teacher.email}</td>
                            { (userType==='A') ? ( 
                              <>
                              <td className="text-bold-500">{teacher.remarks}</td> 
                              <td className="text-bold-500">+{teacher.country_code}</td>
                              <td className="text-bold-500">{teacher.mobile}</td>
                              </>
                            ) : null}
                            
                            <td className="text-bold-500">
                              <div className="buttons">
                                { (userType==='A') ? (
                                <>
                                <Link to={`teacher/${teacher._id}`}><button className="btn btn-primary">View</button></Link>
                                <Link to={`teacher/edit/${teacher._id}`}><button className="btn btn-warning">Edit</button></Link>
                                <button onClick={() => deleteThisTeacherConfirm(teacher._id)} className="btn btn-danger">Delete</button>
                                </>
                                ) : (
                                  <button type="button" onClick={() => showMessageModal(teacher._id)} className="btn btn-info">Chat</button>
                                )}
                              </div>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>

                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Teacher