const initialState = {
	is_active : true,
}

const sidebarReducer = function(state = initialState, action){
	let new_state
	switch(action.type){
		case 'TOGGLE_SIDEBAR':
		
		new_state = {
			...state,
			is_active : !state.is_active
		}
		break;

		default:
		new_state = state
	}
	return new_state
}

export default sidebarReducer