// import PageHeading from '../../components/PageHeading'
import SetTeacherTimeTable from '../../components/Teacher/SetTimeTable'
import { useParams } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import userServices from '../../services/user.service'
import scheduleServices from '../../services/schedule.service'
import { useToasts } from 'react-toast-notifications';

const StudentSchedule = () => {

  const { addToast } = useToasts()
  const {id} = useParams()
  const dispatch = useDispatch()
  const userState = useSelector(state => state.user)
  const student = userState.current_user

  const scheduleState = useSelector(state => state.schedule)
  const current_schedule = scheduleState.schedule

  // Local states
  const [reminder_before, setReminderBefore] = useState(15) // Default to 15 mins
  const [schedule, setSchedule] = useState([])
  const [meeting_url, setMeetingURL] = useState('')
  const [meeting_platform, setMeetingPlatform] = useState('ZOOM')
  const [content, setContent] = useState('')

  const reminder_minutes = [{
    label : '15 mins',
    value : 15
  },{
    label : '30 mins',
    value : 30
  },{
    label : '45 mins',
    value : 45
  },{
    label : '1 hour',
    value : 60
  }]

  // On created
  useEffect(() => {
    userServices.loadUser(dispatch, {id})
    scheduleServices.loadSchedule(dispatch, {filter : {student : id}})
  }, [dispatch, id])

  // Watcher for schedule from state
  useEffect(() => {
    if(current_schedule){
      setReminderBefore(current_schedule.reminder_before)
      setSchedule(current_schedule.schedule)
      setMeetingURL(current_schedule.meeting_url)
      setMeetingPlatform(current_schedule.meeting_platform)
      setContent(current_schedule.content)
    }
  }, [current_schedule])
  // On submitting
  const proceed = () => {
    let upd_data = {
      reminder_before, schedule, meeting_url, meeting_platform, student : id, content
    }

    // We will always be updating the schedule
    scheduleServices.updateSchedule(dispatch, {data : upd_data}).then(() => {
      addToast('Schedule Updated', { appearance: 'success' })
    }).catch(err => {
      addToast(err.response.data, { appearance: 'error' })
    })
  }
  return (
    <section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
        { student ? (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Class Schedule for {student.name}</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
                <div className="form">
                  <div className="row">
                    <div className="col-md-5 col-12 mx-auto">
                      <Form.Group>
                        <Form.Label>Reminder Before</Form.Label>
                        <Form.Control as="select" value={reminder_before} onChange={(e) => setReminderBefore(e.target.value)}>
                          {
                            reminder_minutes.map(lreminder_min => {
                              return <option key={`REM_${lreminder_min.value}`} value={lreminder_min.value} >{lreminder_min.label}</option>
                            })
                          }
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Remarks</Form.Label>
                        <Form.Control as="textarea" value={content} onChange={(e) => setContent(e.target.value)}>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Meeting URL</Form.Label>
                        <Form.Control value={meeting_url} onChange={(e) => setMeetingURL(e.target.value)} type="text" />
                      </Form.Group>
                      
                      <Form.Group>
                        <Form.Label>Meeting Platform</Form.Label>
                        <Form.Control as="select" value={meeting_platform} onChange={(e) => setMeetingPlatform(e.target.value)}>
                          <option value="ZOOM">Zoom</option>
                          <option value="GOOGLE">Google Meet</option>
                          <option value="WEBEX">Cisco Webex</option>
                        </Form.Control>
                      </Form.Group>
                    </div>
                  </div>

                  <h3 className="text-center">Schedule Here</h3>
                  <p className="text-center">Select the class starting time here. For e.g. selecting 9:00 indicates that the class begins at 9:00 am</p>
                  <SetTeacherTimeTable timetable={schedule} onchange={(val) => setSchedule(val)} />

                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <button onClick={proceed} type="button" className="btn btn-primary me-1 mb-1">Submit</button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div> 
        ) : '' }
        </div>
      </div>
    </section>
  )
}

export default StudentSchedule