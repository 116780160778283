import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Form} from 'react-bootstrap'
import Loading from '../../components/Common/Loading'

// Components
import ViewUserSchedules from '../../components/Schedule/ViewUserSchedule'

// Services
import scheduleServices from '../../services/schedule.service'
import userServices from '../../services/user.service'

const TeacherCalendar = () => {

	// Local States 
	const [filter, setFilter] = useState({ teacher : undefined, student : undefined })
	const [isLoading, setIsLoading] = useState(false)

	const dispatch = useDispatch()
	const scheduleState = useSelector(state => state.schedule)
	const schedules = scheduleState.schedules
	const userState = useSelector(state => state.user)
  const teachers = userState.teachers
  const students = userState.students

	useEffect(() => {
    userServices.loadTeachers(dispatch, {})
    userServices.loadStudents(dispatch, {})
	}, [dispatch])

	useEffect(() => {
		// Loading true
		setIsLoading(() => true)

		let local_filter = {}
		for(let filter_key in filter){
			const val = filter[filter_key]
			if(val){
				local_filter[filter_key] = val
			}
		}

		scheduleServices.loadAllSchedules(dispatch, { filter : local_filter }).then(() => {
			// Loading false
			setIsLoading(() => false)

		}).catch(() => {
			// Loading false
			setIsLoading(() => false)			
		})
	}, [filter, dispatch])

	const updateFilter = (key, val) => {
		setFilter(state => {
			return { ...state, [key] : val }
		})
	}

	return (
		<section id="multiple-column-form">
      <div className="row match-height">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Tutors Calendar</h4>
            </div>
            <div className="card-content">
              <div className="card-body">
              	<div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="email-id-column">Filter By Tutors</label>
                      <Form.Control as="select" value={filter.teacher} onChange={(e) => updateFilter('teacher', e.target.value)} >
                        <option value={''}>Select</option>
                        { teachers.map(teacher => {
                        	return (<option key={teacher._id} value={teacher._id}>{teacher.name}</option>)
                        })}
                      </Form.Control>
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="email-id-column">Filter By Students</label>
                      <Form.Control as="select" value={filter.student} onChange={(e) => updateFilter('student', e.target.value)} >
                        <option value={''}>Select</option>
                        { students.map(student => {
                        	return (<option key={student._id} value={student._id}>{student.name}</option>)
                        })}
                      </Form.Control>
                    </div>
                  </div>
                </div>
                <h3 className="text-center">Time-Table (Tutor's timezone is color coded)</h3>

                { isLoading ? (
                	<Loading />
                ) : (
                	<ViewUserSchedules type="B" schedules={schedules} />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
	)
}

export default TeacherCalendar