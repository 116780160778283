const TOKEN_KEY = 'token';
const USER_TYPE_KEY = 'type';
const USER_NAME_KEY = 'name';

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_TYPE_KEY);
    localStorage.removeItem(USER_NAME_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }

    return false;
}

export const userToken = localStorage.getItem(TOKEN_KEY)
export const userType = localStorage.getItem(USER_TYPE_KEY)
export const userName = localStorage.getItem(USER_NAME_KEY)