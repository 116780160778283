import { useState, useEffect } from "react"
import {Form, Button} from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from "react-redux"
import subjectServices from '../../services/subject.service'

const SetSubject = ({subject_id, onHide}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const subjectState = useSelector(state => state.subject)

  const current_subject = subjectState.current_subject
  const [subject_name, setSubjectName] = useState('')
  const [subject_index, setSubjectIndex] = useState(0)
  const [subject_remarks, setSubjectRemarks] = useState('')

  const proceed = () => {
    let subject_data = {
      name : subject_name,
      index : subject_index,
      remarks : subject_remarks
    }
    if(subject_id){
      // If update
      subjectServices.updateSubject(dispatch, { id : subject_id, data : subject_data }).then(() => {
        addToast('Subject updated successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
      })
    }else{
      // If entry
      subjectServices.createSubject(dispatch, { data : subject_data }).then(() => {
        addToast('Subject created successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
      })
    }
  }

  // Watching for new subject id
  useEffect(() => {
    if(subject_id){
      subjectServices.loadSubject(dispatch, {id:subject_id})    
    }else{
      subjectServices.emptySubject(dispatch)    
    }
  }, [subject_id, dispatch])

  // Watching for current loaded subject
  useEffect(() => {
    if(current_subject && subject_id){
      setSubjectName(current_subject.name)
      setSubjectRemarks(current_subject.remarks)
    }
  }, [current_subject])

  return (
    <div>
      <Form.Group>
        <Form.Label>Subject Name</Form.Label>
        <Form.Control value={subject_name} onChange={(e) => setSubjectName(e.target.value)} type="text" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Subject Index</Form.Label>
        <Form.Control value={subject_index} onChange={(e) => setSubjectIndex(e.target.value)} type="number" />
      </Form.Group>

      <Form.Group>
        <Form.Label>Remarks</Form.Label>
        <Form.Control value={subject_remarks} onChange={(e) => setSubjectRemarks(e.target.value)} rows="3" as="textarea" />
      </Form.Group>

      <Button className="btn-center" onClick={proceed}>Save</Button>
    </div>
  )
}

export default SetSubject