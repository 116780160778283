// Regarding API
const axios = require('axios')
const {baseURL} = require('../assets/js/config')
const $http = axios.create({
	baseURL
})
const {userToken} = require('../utils')

//Loading all grades
const loadGradeSubjects = (dispatch) => {
	return $http.get('gradesubject', {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADE_SUBJECTS',
      		payload : res.data
    	})
	})
}

//Loading a specific grade
function loadGradeSubject(dispatch, {id}){
	return $http.get(`gradesubject/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADE_SUBJECT',
      		payload : res.data
    	})
    	return res
	})
}

//Creating a new grade
function createGradeSubject(dispatch, {data}){
	return $http.post(`gradesubject`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'ADD_GRADE_SUBJECT',
      		payload : res.data
    	})
	})
}

//Updating a grade
function updateGradeSubject(dispatch, {id, data}){
	return $http.put(`gradesubject/${id}`, data, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'SET_GRADE_SUBJECT',
      		payload : res.data
    	})
	})
}

//Loading a specific grade
function emptyGradeSubject(dispatch){
	dispatch({
  		type : 'SET_GRADE_SUBJECT',
  		payload : null
	})
}

//Deleting a grade
function deleteGradeSubject(dispatch, {id}){
	return $http.delete(`gradesubject/${id}`, {
		headers : {
			'x-auth-token' : userToken
		}
	}).then(res => {
		dispatch({
      		type : 'REMOVE_GRADE_SUBJECT',
      		payload : {_id : id}
    	})
	})
}

const gradeServices = {
	loadGradeSubjects, loadGradeSubject, createGradeSubject, updateGradeSubject, emptyGradeSubject, deleteGradeSubject
}

export default gradeServices