import { createStore, combineReducers } from "redux";
// Importing all reducers
import usersReducer from "./usersReducer";
import gradesReducer from "./gradesReducer";
import couponsReducer from "./couponReducer";
import subscribersReducer from "./subscriberReducer";
import subjectsReducer from "./subjectsReducer";
import gradeSubjectsReducer from "./gradeSubjectsReducer";
import authReducer from "./authReducer";
import schedulesReducer from "./schedulesReducer";
import attendanceReducer from "./attendanceReducer";
import materialsReducer from "./materialsReducer";
import remindersReducer from "./remindersReducer";
import chatsReducer from "./chatsReducer";
import sidebarReducer from "./sidebarReducer";
import tutorAvailabilitiesReducer from "./tutorAvailabilitiesReducer";

const store = createStore(
  combineReducers({
    user: usersReducer,
    grade: gradesReducer,
    subject: subjectsReducer,
    grade_subject: gradeSubjectsReducer,
    auth: authReducer,
    schedule: schedulesReducer,
    attendance: attendanceReducer,
    material: materialsReducer,
    reminder: remindersReducer,
    chat: chatsReducer,
    sidebar: sidebarReducer,
    tutor_availability: tutorAvailabilitiesReducer,
    coupon: couponsReducer,
    subscriber: subscribersReducer,
  })
);

export default store;
