import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Form, Button, Spinner} from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications';
import ViewFile from '../../components/General/ViewFile'
// Import React FilePond
import { FilePond } from 'react-filepond'

// Importing services
import materialServices from '../../services/material.service'
import userServices from '../../services/user.service'
import {baseURL} from '../../assets/js/config'
import { userType } from '../../utils'

const SetMaterial = ({material_id, onHide}) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [isLoading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [remarks, setRemarks] = useState('')
  const [to, setTo] = useState(null)
  const [editfiles, setEditFiles] = useState([])

  // Material State
  const materialState = useSelector(state => state.material)
  const material = materialState.current_material

  // User State
  const userState = useSelector(state => state.user)
  const students = userState.students
  const teachers = userState.teachers


  //Files
  const [files, setFiles] = useState([])

  const proceed = () => {
    setLoading(_ => true)
    const material_data = {
      title,
      to,
      remarks,
      files,
      editfiles,
    }

    
    if(material_id){
      // For updating
      materialServices.updateMaterial(dispatch, {
        id : material_id,
        data : material_data
      }).then(() => {
        addToast('Material updated successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
        setLoading(_ => false)
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
        setLoading(_ => false)
      })
    }else{
      // For creating
      materialServices.createMaterial(dispatch, {
        data : material_data
      }).then(() => {
        addToast('Material created successfully', { appearance: 'success' })
        if(onHide){
          onHide()
        }
        setLoading(_ => false)
      }).catch(err => {
        addToast(err.response.data, { appearance: 'error' })
        setLoading(_ => false)
      })
    }
    
  }

  // Created
  useEffect(() => {
    if(userType === 'S'){
      userServices.loadTeachers(dispatch)
    }else if(userType === 'T'){
      userServices.loadStudents(dispatch)
    }

  }, [dispatch])

  // Watching for new material or created
  useEffect(() => {
    if(material_id){
      setLoading(true)
      materialServices.loadMaterial(dispatch, {id : material_id}).then(() => {
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        addToast(err.response.data, { appearance: 'error' })
      }) 
    }else{
      materialServices.emptyMaterial(dispatch).then(() => {
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        addToast(err.response.data, { appearance: 'error' })
      }) 
    }

  }, [material_id, dispatch, addToast])

  // Watching for material object
  useEffect(() => {
    if(material){
      setTitle(material.title)
      setRemarks(material.remarks)
      setTo(material.to._id)
      let local_editfiles = material.files.map(file_url => {
        return {
          file : file_url,
          is_deleted : false
        }
      })
      setEditFiles(local_editfiles)
    }else{
      setTitle("")
      setRemarks("")
      setTo(null)
      setEditFiles([])
    }
  }, [material])

  const filepondServer = {
    url : `${baseURL}`,
    process: {
      url: `upload`,
      method: 'POST',
      timeout: 7000,
      onload : (file_arr) => {
        // This return object is a string.
        file_arr = JSON.parse(file_arr)
        setFiles(old_files => [...old_files, ...file_arr])
      } 
    } 
  }

  const removeFile = (file_i) => {
    let local_editfiles = JSON.parse(JSON.stringify(editfiles))
    local_editfiles[file_i].is_deleted = true
    setEditFiles(local_editfiles)
  }

  return (
    <div>
      { isLoading ? <Spinner className="btn-center" animation="border" /> : (
      <div>
        <Form.Group>
          <Form.Label>Title</Form.Label>
          <Form.Control value={title} onChange={(e) => setTitle(e.target.value)}></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Remarks</Form.Label>
          <Form.Control value={remarks} as="textarea" onChange={(e) => setRemarks(e.target.value)}></Form.Control>
        </Form.Group>

        <Form.Group>
          <Form.Label>Share To</Form.Label>
          <Form.Control as="select" defaultValue={to} onChange={(e) => setTo(e.target.value)}>
            <option value={null}  hidden>Select</option>
            {
              [...students, ...teachers].map(luser => {
                return <option key={luser._id} value={luser._id} >{luser.name}</option>
              })
            }
          </Form.Control>
        </Form.Group>

        { (editfiles && editfiles.filter(file_obj => !file_obj.is_deleted).length) ? (
        <div>
          <h3 className="text-center">Uploaded Files</h3>
          <div className="row">
            { editfiles.filter(file_obj => !file_obj.is_deleted).map((file, file_i) => {
              return (
              <div key={`FILE_${file_i}`} className="col-6">
                <ViewFile show_remove={true} url={file.file} onRemove={() => removeFile(file_i)} />
              </div>
            ) }) }
          </div>
        </div>) : ''}


        <Form.Group>
          <Form.Label>Share files</Form.Label>
          <FilePond allowMultiple={true} maxFiles={5} name="files" server={filepondServer}
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>' />  
        </Form.Group>      

        <Button className="btn-center" onClick={() => proceed()}>{material_id ? 'Update' : 'Create'}</Button>
      </div>
      ) }
    </div>
  )
}

export default SetMaterial