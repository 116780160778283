import {useHistory} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import { useDispatch } from "react-redux";

import SideBarCollapseIcon from '../assets/images/icons/sidebar-collapse.png'
const {logout} = require('../utils')

const Header = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const logoutNow = () => {
        logout()
        history.push('/login')
    }

    const toggleSidebar = () => {
        dispatch({
            type : 'TOGGLE_SIDEBAR'
        })
    }
	return (
		<header className="mb-3">
            {/*<a className="burger-btn d-block d-xl-none">
                <i className="bi bi-justify fs-3"></i>
            </a>*/}
            <div className="buttons" style={{'justifyContent' : 'flex-end', 'display' : 'flex'}}>
                <Button onClick={() => logoutNow()}>Logout</Button>
                <Button className="sidebar-collapse" variant="light" onClick={() => toggleSidebar()}>
                    <img alt="Sidebar Collapse" height="20" src={ SideBarCollapseIcon } />
                </Button>
            </div>
        </header>
	)
}

export default Header